import React, { useEffect, useState } from "react";
import GSAoutUsDesc from "./GSAboutUsDesc";
import GSHeading from "../GSHeading/GSHeading";
import { numberToIndianWords } from "../../../Other Functions/numberToIndianWords";
export default function GSAboutUs() {
  const arr = [
    {
      name: "Product 1",
      productDesc: "Desc 1",
      price: "2889",
      img: "https://images.pexels.com/photos/20354062/pexels-photo-20354062/free-photo-of-a-man-is-standing-in-front-of-a-large-circular-object.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      name: "Product 2",
      productDesc: "Desc 2",
      price: "2445",
      img: "https://images.pexels.com/photos/20175203/pexels-photo-20175203/free-photo-of-black-and-white.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      name: "Product 3",
      productDesc: "Desc 3",
      price: "3452",
      img: "https://images.pexels.com/photos/20448103/pexels-photo-20448103/free-photo-of-a-field-of-pink-and-white-flowers-with-a-sign-that-says-cosmos.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      name: "Product 4",
      productDesc: "Desc 4",
      price: "9874",
      img: "https://images.pexels.com/photos/19552713/pexels-photo-19552713/free-photo-of-silhouette-of-man-walking-in-front-of-a-mosque-in-black-and-white.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
  ];

  return (
    <div>
      <GSHeading />
      <h1 style={{ marginTop: "70px" }}>About us</h1>
      <GSAoutUsDesc />
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
        }}
      >
        {arr.map((x) => (
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              cursor: "pointer",
            }}
            onClick={() => alert(x.name + " Not found")}
          >
            <img src={x.img} alt="Product Image" style={{ width: "200px" }} />
            <h4>{x.name}</h4>

            <p>{x.productDesc}</p>

            <p>₹{x.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
