import React, { useCallback, useEffect, useRef, useState } from "react";
import AdminHeading from "../Heading/AdminHeading";
import AdminBreadCrump from "../Heading/AdminBreadCrump";
import AlertMessage from "../../../Other Functions/AlertMessage";
import { useSelector } from "react-redux";
import {
  a16,
  a18,
  a20,
  a22,
  a24,
  a28,
  a30,
  a31,
  a33,
  a41,
  a43,
  a47,
  a55,
  a57,
  a71,
  a8,
  a90,
} from "../../../Api/RootApiPath";
import QRCode from "qrcode";
import jsPDF from "jspdf";
import { InfinitySpin } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineEdit, AiOutlineFileAdd } from "react-icons/ai";
import { BiSave, BiListUl } from "react-icons/bi";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { BsImages } from "react-icons/bs";
import { IoIosAddCircleOutline } from "react-icons/io";
import { CiImport } from "react-icons/ci";

export default function AdminAddBulkProducts() {
  const [qr, setQr] = useState("");
  const [productName, setProductName] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  //   const [categoryId, setCategoryId] = useState("");
  const [pieces, setPieces] = useState(1);
  const [huid, setHuid] = useState("");
  const [netWt, setNetWt] = useState(0);
  const [size, setSize] = useState(0);
  const [hallmark, setHallmark] = useState("");
  const [hallmarkAmount, setHallmarkAmount] = useState("0");
  const [grosswt, setGrosswt] = useState(0);
  const [purity, setPurity] = useState("");
  const [collection, setCollection] = useState("");
  const [occasion, setOccasion] = useState("");
  const [gender, setGender] = useState("");
  const [description, setDescription] = useState("");
  // const [productTypeId, setProductTypeId] = useState("");
  const [partyTypeId, setPartyTypeId] = useState("");
  const [boxId, setBoxId] = useState(1);
  const [making_per_gram, setMaking_per_gram] = useState(0);
  const [making_Fixed_Amt, setMaking_Fixed_Amt] = useState(0);
  const [making_Percentage, setMaking_Percentage] = useState(0);
  const [making_Fixed_Wastage, setMaking_Fixed_Wastage] = useState(0);
  const [stoneWeight, setStoneWeight] = useState(0);
  const [stoneAmount, setStoneAmount] = useState(0);
  const [featured, setFeatured] = useState("");
  const [productCode, setProductCode] = useState("");
  const [mrp, setMRP] = useState(0);
  const [itemCode, setItemCode] = useState("");
  const [itemType, setItemType] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [finePerc, setFinePerc] = useState("0");
  const [wastagePerc, setWastagePerc] = useState("0");
  const [fineWastagePerc, setFineWastagePerc] = useState("0");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [category, setCategory] = useState("");
  const [productTypeData, setProductTypeData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [purityData, setPurityData] = useState([]);
  const [partyData, setPartyData] = useState([]);
  const [boxData, setBoxData] = useState([]);
  const [collectionTypeData, setCollectionTypeData] = useState([]);
  const [addedProducts, setAddedProducts] = useState([]);
  const [parameter, setParameter] = useState("");
  const [formValue, setFormValue] = useState("");
  const [productType, setProductType] = useState("");
  const [productInEdit, setProductInEdit] = useState([]);
  const [productInEditImages, setProductInEditImages] = useState();
  const [updatedProducts, setUpdatedProducts] = useState([]);
  const [showImportPopup, setShowImportPopup] = useState(false);
  const [importFile, setImportFile] = useState([]);

  const [loading, setLoading] = useState(false);
  // const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(true);

  const [goldAlert, setGoldAlert] = useState(false);
  const [barCodeAlert, setBarCodeAlert] = useState(false);
  const [importAlert, setImportAlert] = useState(false);
  const [firebaseData, setFirebaseData] = useState({});
  const [isMounted, setIsMounted] = useState(false);
  const [rifdData, setRifdData] = useState([]);
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [selectedItemCodes, setSelectedItemCodes] = useState([]);
  const [allItemCodesArray, setAllItemCodesArray] = useState([]);
  const [deleteAll, setDeleteAll] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState(false);
  const [showAllFields, setShowAllFields] = useState(false);
  const [stockType, setStockType] = useState("Labelled");
  const [branch, setBranch] = useState("Home");

  // new logic for barcode and tid below
  const [barcodeNumbersArray, setBarcodeNumbersArray] = useState([]);
  const [piecesBox, setPiecesBox] = useState(false);
  const [productPiecesEditId, setProductPiecesEditId] = useState(0);
  const [halfInputs, setHalfInputs] = useState(true);
  // const [barcodeNumbersArray, setBarcodeNumbersArray] = useState([]);

  const [allSku, setAllSku] = useState([]);
  const useWarnIfUnsavedChanges = (hasUnsavedChanges) => {
    const navigate = useNavigate();

    const customNavigate = useCallback(
      (to, options = {}) => {
        if (
          hasUnsavedChanges &&
          !window.confirm(
            "You have unsaved changes. Are you sure you want to leave?"
          )
        ) {
          // User does not confirm navigation, do nothing.
          return;
        }

        // No unsaved changes or user confirmed, proceed with navigation.
        navigate(to, options);
      },
      [hasUnsavedChanges, navigate]
    );

    return customNavigate;
  };
  const customNavigate = useWarnIfUnsavedChanges(hasUnsavedChanges);

  const handlePiecesChange = (value, idRcvd, close) => {
    const updatedProducts = addedProducts.map((product) => {
      if (product.id === idRcvd) {
        const arrayOfObjects = [];

        for (let i = 0; i < value; i++) {
          const object = {
            key: `value${i + 1}`,
          };
          arrayOfObjects.push(object);
        }

        setBarcodeNumbersArray(arrayOfObjects);
      }
    });
  };
  // console.log(rifdData, "rifdData");
  const closePiecesEditBox = () => {
    const updatedProducts = addedProducts.map((product) => {
      if (product.id === productPiecesEditId) {
        return {
          ...product,
          pieces: 1,
        };
      }
      return product;
    });
    setAddedProducts(updatedProducts);
    setPiecesBox(false);
  };
  const handleBarcodeNumberChange = (newValue, index) => {
    // Convert the barcode number to uppercase
    const uppercaseBarcodeNumber = newValue.toUpperCase();

    // Find a matching product in the rifdData array based on uppercaseBarcodeNumber
    const matchingProduct = rifdData.find(
      (item) => item.barcodeNumber === uppercaseBarcodeNumber
    );

    setBarcodeNumbersArray((prevBarcodeNumbersArray) => {
      const updatedArray = [...prevBarcodeNumbersArray];

      if (uppercaseBarcodeNumber.trim() === "") {
        // If barcode number is empty, update the array with an empty object
        updatedArray[index] = {};
      } else if (matchingProduct) {
        // If a matching product is found, update tid with the matching product's tid value
        const updatedItem = {
          [uppercaseBarcodeNumber]: matchingProduct.tid,
        };
        updatedArray[index] = updatedItem;
      } else {
        // If no matching product is found, set tid to an empty string
        const updatedItem = {
          [uppercaseBarcodeNumber]: "",
        };
        updatedArray[index] = updatedItem;
      }

      return updatedArray;
    });
  };
  let barcodeNumberString = "";
  let tidNumberString = "";
  const handleCheckTidValues = () => {
    // Check if all tid values are non-empty, unique, and do not include the word 'value'
    const uniqueTidValues = new Set(
      barcodeNumbersArray.map((item) => {
        const tidValue = Object.values(item)[0];
        return tidValue !== null &&
          tidValue !== "" &&
          !tidValue.toLowerCase().includes("value")
          ? tidValue
          : null;
      })
    );

    // Check if all barcode numbers are unique and do not include their key names
    const allBarcodeNumbersValid = barcodeNumbersArray.every((item) => {
      const barcodeNumber = Object.keys(item)[0];
      const tidValue = Object.values(item)[0];
      return (
        barcodeNumber !== tidValue &&
        !barcodeNumber.toLowerCase().includes("key")
      );
    });

    if (
      uniqueTidValues.size === barcodeNumbersArray.length &&
      allBarcodeNumbersValid
    ) {
      // Generate barcodeNumberString and tidNumberString
      const barcodeNumberString = Array.from(
        barcodeNumbersArray.map((item) => Object.keys(item)[0])
      ).join(","); // Join barcode numbers with commas
      const tidNumberString = Array.from(uniqueTidValues).join(","); // Join unique tid values with commas

      // Now you can use barcodeNumberString and tidNumberString as needed
      // console.log("barcodeNumberString:", barcodeNumberString);
      // console.log("tidNumberString:", tidNumberString);

      // Search for the product in addedProducts array with matching id and update barcodeNumber and tid
      const updatedProducts = addedProducts.map((product) => {
        if (product.id === productPiecesEditId) {
          return {
            ...product,
            barcodeNumber: barcodeNumberString,
            tid: tidNumberString,
          };
        }
        return product;
      });

      // Set the state with updated products
      setAddedProducts(updatedProducts);
      setPiecesBox(false);
    } else {
      if (uniqueTidValues.size !== barcodeNumbersArray.length) {
        alert(
          "Not all tid values are non-empty, unique, or contain the word 'value'."
        );
      }

      if (!allBarcodeNumbersValid) {
        alert("Invalid barcode numbers.");
      }
    }
  };

  // console.log("barcodeNumberString:", barcodeNumberString);
  // console.log("tidNumberString:", tidNumberString);
  // console.log("barcodeNumberString:", barcodeNumberString);
  // console.log("tidNumberString:", tidNumberString);
  useEffect(() => {
    if (!piecesBox) {
      setBarcodeNumbersArray([]);
    }
  }, [piecesBox]);
  // console.log(addedProducts, "addedProducts for barcode");
  // console.log(barcodeNumbersArray, "barcodeNumbersArray");
  // new logic for barcode and tid above

  const allStates = useSelector((state) => state);
  const adminLoggedIn = allStates.reducer1;
  let Entryby_Staff_id = parseInt(adminLoggedIn);

  const scrollToCenter = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    // console.log("scroll");
  };
  useEffect(() => {
    fetch(a18)
      .then((res) => res.json())
      .then((data) => setCategoriesData(data.data));
  }, []);
  useEffect(() => {
    fetch(a20)
      .then((x) => x.json())
      .then((y) => setProductTypeData(y.data));
  }, []);
  // console.log(productTypeData.category_id);

  useEffect(() => {
    fetch(a22)
      .then((res) => res.json())
      .then((data) => setPurityData(data.data));
    // console.log(purityData);
  }, []);
  useEffect(() => {
    fetch(a28)
      .then((res) => res.json())
      .then((data) => setPartyData(data.data.result));
  }, []);
  useEffect(() => {
    fetch(a33)
      .then((res) => res.json())
      .then((data) => setCollectionTypeData(data.data));
  }, []);
  // console.log(partyData);
  useEffect(() => {
    fetch(a30)
      .then((res) => res.json())
      .then((data) => setBoxData(data.data));
  }, []);
  const fetchAllSku = async () => {
    try {
      const response = await fetch(a71);
      const data = await response.json();
      console.log(data);
      setAllSku(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllSku();
  }, []);
  let categoryId = parseInt(category.split(",")[0]);
  let categoryName = category.split(",")[1];
  let productTypeId = parseInt(productType.split(",")[0]);
  let productTypeName = productType.split(",")[1];
  let collectionId = parseInt(collection.split(",")[0]);
  let collectionName = collection.split(",")[1];
  let purityId = parseInt(purity.split(",")[0]);
  let purityName = purity.split(",")[1];
  let partyId = parseInt(partyTypeId.split(",")[0]);
  let partyName = partyTypeId.split(",")[1];

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    if (5 > files.length > 0) {
      const newFiles = Array.from(files).slice(0, 5 - selectedFiles.length);
      setSelectedFiles([...selectedFiles, ...newFiles]);
    }
  };

  useEffect(() => {
    fetch(a43)
      .then((res) => res.json())
      .then((data) => setRifdData(data.data)),
      setLoadingAdd(true);
  }, []);

  // console.log(rifdData, "rifdData");

  // Skkhandre label below
  const openLabelInNew = async (products) => {
    const doc = new jsPDF({
      // format: [26, 12],
      format: [28, 13],
      orientation: "landscape",
    });

    const fontSize = 7;
    const imageHeight = 7;
    const imageWidth = 7;

    for (let i = 0; i < products.length; i++) {
      const {
        collection,
        grosswt,
        stoneWeight,
        netWt,
        stoneAmount,
        itemCode,
        purity,
        mrp,
        product_No,
        pieces,
        description,
        barcodeNumber,
      } = products[i];

      if (i > 0) {
        doc.addPage(); // Add a new page for each product after the first one
      }
      doc.setFontSize(fontSize);
      doc.setFont("helvetica", "bold");
      // {
      //   collection.length > 20
      //     ? doc.text(`${collection.substr(0, 26)}`, 1, 3)
      //     : doc.text(`${collection}`, 1, 3);
      // }

      if (mrp == 0 || mrp === "") {
        // doc.text(`${itemCode}`, 2, 3);
        doc.text(`G.Wt: ${parseFloat(grosswt).toFixed(3)}`, 2, 3);
        doc.text(`S.Wt: ${parseFloat(stoneWeight).toFixed(3)}`, 2, 6);
        doc.text(`N.Wt: ${parseFloat(netWt).toFixed(3)}`, 2, 9);
        // doc.text(`S.Wt: ${parseFloat(stoneWeight).toFixed(3)}`, 4, 8);
        // doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 20, 8);
        doc.text(`Pcs:${pieces}`, 19, 11.5);
        // doc.text(`${product_No}`, 4, 11.5);
        doc.text(`${itemCode}`, 18, 6);
        doc.text(`${purity}`, 21, 3);
        doc.setFontSize(7);
        {
          barcodeNumber
            ? doc.text(`${barcodeNumber}`, 2, 11.5)
            : doc.text("", 2, 11.5);
        }
        // doc.setFontSize(5);
        // const maxLineLength = 27;
        // const descriptionLine1 = description.substring(0, maxLineLength);
        // const descriptionLine2 = description.substring(
        //   maxLineLength,
        //   maxLineLength * 2
        // );

        // doc.text(descriptionLine1, 4, 10);
        // doc.text(descriptionLine2, 4, 11.5);
      } else {
        doc.text(`G.Wt: ${parseFloat(grosswt).toFixed(3)}`, 2, 3);
        doc.text(`MRP: ${parseFloat(mrp)}`, 2, 6);
        doc.text(`Pcs:${pieces}`, 19, 11.5);
        doc.text(`${itemCode}`, 18, 6);
        // doc.text(`${product_No}`, 4, 11.5);
        // doc.text(`${product_No}`, 4, 11.5);
        // doc.text(`${itemCode}`, 3, 3);
        doc.text(`${purity}`, 21, 3);
        {
          barcodeNumber
            ? doc.text(`${barcodeNumber}`, 2, 11.5)
            : doc.text("", 2, 11.5);
        }
      }

      try {
        // const qrCodeDataUrl = await QRCode.toDataURL(itemCode);
        // doc.addImage(qrCodeDataUrl, "JPEG", 3, 3, imageWidth, imageHeight);
      } catch (error) {
        console.error(error);
      }
    }

    const pdfData = doc.output("datauristring");
    const newWindow = window.open();
    newWindow.document.write(
      `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
    );
  };

  // Soni label below
  // const openLabelInNew = async (products) => {
  //   const doc = new jsPDF({
  //     // format: [26, 12],
  //     format: [100, 13],
  //     orientation: "landscape",
  //   });

  //   const fontSize = 9;
  //   const imageHeight = 12;
  //   const imageWidth = 12;

  //   for (let i = 0; i < products.length; i++) {
  //     const {
  //       collection,
  //       grosswt,
  //       stoneWeight,
  //       netWt,
  //       stoneAmount,
  //       diamondWeight,
  //       itemCode,
  //       purity,
  //       mrp,
  //       product_No,
  //       pieces,
  //       description,
  //     } = products[i];

  //     if (i > 0) {
  //       doc.addPage(); // Add a new page for each product after the first one
  //     }

  //     doc.setFontSize(fontSize);
  //     // {
  //     //   collection.length > 20
  //     //     ? doc.text(`${collection.substr(0, 26)}`, 1, 3)
  //     //     : doc.text(`${collection}`, 1, 3);
  //     // }

  //     if (mrp == 0 || mrp === "") {
  //       doc.text(`G.Wt: ${parseFloat(grosswt).toFixed(3)}`, 55, 3);
  //       doc.text(`N.Wt: ${parseFloat(netWt).toFixed(3)}`, 55, 6);
  //       // doc.text(`${purity}`, 30, 6);
  //       // doc.text(`S.Wt: ${parseFloat(stoneWeight).toFixed(3)}`, 4, 12);
  //       // doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 30, 12);
  //       doc.text(diamondWeight ? `Crt: ${diamondWeight}` : "Crt: ", 25, 3);
  //       doc.text(`Pcs:${pieces}`, 25, 6);
  //       // doc.text(`${product_No}`, 4, 11.5);
  //       doc.text(`${itemCode}`, 25, 9);
  //       doc.setFontSize(5);
  //       const maxLineLength = 40;
  //       const descriptionLine1 = description.substring(0, maxLineLength);
  //       const descriptionLine2 = description.substring(
  //         maxLineLength,
  //         maxLineLength * 2
  //       );

  //       doc.text(descriptionLine1, 55, 9);
  //       doc.text(descriptionLine2, 55, 11.5);
  //     } else {
  //       doc.text(`G.Wt: ${parseFloat(grosswt).toFixed(3)}`, 30, 6);
  //       doc.text(`MRP: ${parseFloat(mrp)}`, 11, 9);
  //       doc.text(`Pcs:${pieces}`, 30, 2);
  //       // doc.text(`${product_No}`, 4, 11.5);
  //       doc.text(`${product_No}`, 4, 12);
  //       doc.text(`${itemCode}`, 4, 3);
  //       doc.text(`${purity}`, 30, 12);
  //     }

  //     try {
  //       const qrCodeDataUrl = await QRCode.toDataURL(itemCode);
  //       doc.addImage(qrCodeDataUrl, "JPEG", 7, 1, imageWidth, imageHeight);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }

  //   const pdfData = doc.output("datauristring");
  //   const newWindow = window.open();
  //   newWindow.document.write(
  //     `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
  //   );
  // };

  // NDDarbar Label Below
  // const openLabelInNew = async (products) => {
  //   const doc = new jsPDF({
  //     format: [29, 12],
  //     orientation: "landscape",
  //   });

  //   const fontSize = 6;
  //   const imageHeight = 7;
  //   const imageWidth = 7;

  //   for (let i = 0; i < products.length; i++) {
  //     const {
  //       collection,
  //       grosswt,
  //       stoneWeight,
  //       stoneAmount,
  //       netWt,
  //       itemCode,
  //       purity,
  //       mrp,
  //       product_No,
  //       pieces,
  //       making_Fixed_Wastage,
  //       making_Percentage,
  //     } = products[i];

  //     // console.log("products", products);
  //     if (i > 0) {
  //       doc.addPage(); // Add a new page for each product after the first one
  //     }

  //     doc.setFontSize(fontSize);
  //     doc.setFont("helvetica", "bold");
  //     // {
  //     //   collection.length > 20
  //     //     ? doc.text(`${collection.substr(0, 26)}`, 1, 3)
  //     //     : doc.text(`${collection}`, 1, 3);
  //     // }

  //     if (mrp == 0 || mrp === "") {
  //       doc.text(`G.WT: ${parseFloat(grosswt).toFixed(3)}`, 3, 3);
  //       doc.text(`S.WT: ${parseFloat(stoneWeight).toFixed(3)}`, 3, 5.5);
  //       if (
  //         parseFloat(making_Percentage) !== 0 &&
  //         making_Percentage !== "" &&
  //         making_Fixed_Wastage !== 0 &&
  //         making_Fixed_Wastage !== ""
  //       ) {
  //         doc.text(
  //           `W.WT: ${(
  //             parseFloat(netWt) / parseFloat(making_Percentage) +
  //             parseFloat(making_Fixed_Wastage)
  //           ).toFixed(3)}`,
  //           3,
  //           7.5
  //         );
  //         doc.text(
  //           `N.WT: ${(
  //             parseFloat(netWt) +
  //             parseFloat(netWt / making_Percentage) +
  //             parseFloat(making_Fixed_Wastage)
  //           ).toFixed(3)}`,
  //           3,
  //           10
  //         );

  //         doc.text(`${itemCode}`, 18, 3);
  //         doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 18, 5.5);
  //         doc.text(`${purity}`, 18, 7.5);
  //         doc.text(`Pc:${pieces}`, 18, 10);
  //       } else if (
  //         parseFloat(making_Percentage) !== 0 &&
  //         making_Percentage !== ""
  //       ) {
  //         doc.text(
  //           `W.WT: ${(
  //             parseFloat(netWt) / parseFloat(making_Percentage)
  //           ).toFixed(3)}`,
  //           3,
  //           7.5
  //         );
  //         doc.text(
  //           `N.WT: ${(
  //             parseFloat(netWt) + parseFloat(netWt / making_Percentage)
  //           ).toFixed(3)}`,
  //           3,
  //           10
  //         );

  //         doc.text(`${itemCode}`, 18, 3);
  //         doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 18, 5.5);
  //         doc.text(`${purity}`, 18, 7.5);
  //         doc.text(`Pc:${pieces}`, 18, 10);
  //       } else if (making_Fixed_Wastage !== 0 && making_Fixed_Wastage !== "") {
  //         doc.text(
  //           `W.WT: ${parseFloat(making_Fixed_Wastage).toFixed(3)}`,
  //           3,
  //           7.5
  //         );
  //         doc.text(
  //           `N.WT: ${(
  //             parseFloat(making_Fixed_Wastage) + parseFloat(netWt)
  //           ).toFixed(3)}`,
  //           3,
  //           10
  //         );

  //         doc.text(`${itemCode}`, 18, 3);
  //         doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 18, 5.5);
  //         doc.text(`${purity}`, 18, 7.5);
  //         doc.text(`Pc:${pieces}`, 18, 10);
  //       } else {
  //         doc.text(`W.WT: N/A`, 3, 8);
  //         doc.text(`N.WT: ${netWt.toFixed(3)}`, 3, 10.5);

  //         doc.text(`${itemCode}`, 18, 3);
  //         doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 18, 5.5);
  //         doc.text(`${purity}`, 18, 7.5);
  //         doc.text(`Pc:${pieces}`, 18, 10);
  //       }
  //     } else {
  //       doc.text(`G.WT: ${grosswt.toFixed(3)}`, 3, 3);
  //       doc.text(`MRP: ${parseInt(mrp)}`, 3, 6);
  //       doc.text(`Pc:${pieces}`, 18, 9);
  //       // doc.text(`${product_No}`, 4, 11.5);
  //       doc.text(`${itemCode}`, 18, 3);
  //       doc.text(`${purity}`, 18, 6);
  //     }
  //   }
  //   const pdfData = doc.output("datauristring");
  //   const newWindow = window.open();
  //   newWindow.document.write(
  //     `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
  //   );
  // };

  // Madan Label Below
  // const openLabelInNew = async (products) => {
  //   const doc = new jsPDF({
  //     // format: [26, 12],
  //     format: [28, 12],
  //     orientation: "landscape",
  //   });

  //   const fontSize = 6;
  //   const imageHeight = 7;
  //   const imageWidth = 7;

  //   for (let i = 0; i < products.length; i++) {
  //     const {
  //       collection,
  //       grosswt,
  //       stoneWeight,
  //       netWt,
  //       stoneAmount,
  //       itemCode,
  //       purity,
  //       mrp,
  //       product_No,
  //       pieces,
  //       description,
  //     } = products[i];

  //     if (i > 0) {
  //       doc.addPage(); // Add a new page for each product after the first one
  //     }

  //     doc.setFontSize(fontSize);
  //     // {
  //     //   collection.length > 20
  //     //     ? doc.text(`${collection.substr(0, 26)}`, 1, 3)
  //     //     : doc.text(`${collection}`, 1, 3);
  //     // }

  //     if (mrp == 0 || mrp === "") {
  //       doc.text(`G.Wt: ${parseFloat(grosswt).toFixed(3)}`, 4, 4);
  //       doc.text(`N.Wt: ${parseFloat(netWt).toFixed(3)}`, 4, 6);
  //       doc.text(`S.Wt: ${parseFloat(stoneWeight).toFixed(3)}`, 4, 8);
  //       doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 20, 8);
  //       doc.text(`Pcs:${pieces}`, 18, 2);
  //       // doc.text(`${product_No}`, 4, 11.5);
  //       doc.text(`${itemCode}`, 4, 2);
  //       doc.text(`${purity}`, 22, 4);
  //       doc.setFontSize(5);
  //       const maxLineLength = 27;
  //       const descriptionLine1 = description.substring(0, maxLineLength);
  //       const descriptionLine2 = description.substring(
  //         maxLineLength,
  //         maxLineLength * 2
  //       );

  //       doc.text(descriptionLine1, 4, 10);
  //       doc.text(descriptionLine2, 4, 11.5);
  //     } else {
  //       doc.text(`G.Wt: ${parseFloat(grosswt).toFixed(3)}`, 11, 5.5);
  //       doc.text(`MRP: ${parseFloat(mrp)}`, 11, 7.5);
  //       doc.text(`Pcs:${pieces}`, 18, 2);
  //       // doc.text(`${product_No}`, 4, 11.5);
  //       doc.text(`${product_No}`, 4, 11.5);
  //       doc.text(`${itemCode}`, 4, 3);
  //       doc.text(`${purity}`, 18, 11.5);
  //     }

  //     try {
  //       const qrCodeDataUrl = await QRCode.toDataURL(itemCode);
  //       // doc.addImage(qrCodeDataUrl, "JPEG", 3, 3, imageWidth, imageHeight);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }

  //   const pdfData = doc.output("datauristring");
  //   const newWindow = window.open();
  //   newWindow.document.write(
  //     `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
  //   );
  // };

  // Soni New design
  // const openLabelInNew = async (products) => {
  //   const doc = new jsPDF({
  //     // format: [26, 12],
  //     format: [28, 12],
  //     orientation: "landscape",
  //   });

  //   const fontSize = 8;
  //   const imageHeight = 7;
  //   const imageWidth = 7;

  //   for (let i = 0; i < products.length; i++) {
  //     const {
  //       collection,
  //       grosswt,
  //       stoneWeight,
  //       netWt,
  //       stoneAmount,
  //       itemCode,
  //       purity,
  //       mrp,
  //       product_No,
  //       pieces,
  //       description,
  //     } = products[i];

  //     if (i > 0) {
  //       doc.addPage(); // Add a new page for each product after the first one
  //     }
  //     doc.setFontSize(fontSize);
  //     // {
  //     //   collection.length > 20
  //     //     ? doc.text(`${collection.substr(0, 26)}`, 1, 3)
  //     //     : doc.text(`${collection}`, 1, 3);
  //     // }

  //     if (mrp == 0 || mrp === "") {
  //       doc.text(`G.Wt: ${parseFloat(grosswt).toFixed(3)}`, 3, 6);
  //       doc.text(`N.Wt: ${parseFloat(netWt).toFixed(3)}`, 3, 9);
  //       // doc.text(`S.Wt: ${parseFloat(stoneWeight).toFixed(3)}`, 4, 8);
  //       // doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 20, 8);
  //       doc.text(`Pcs:${pieces}`, 18, 3);
  //       // doc.text(`${product_No}`, 4, 11.5);
  //       doc.text(`${itemCode}`, 3, 3);
  //       doc.text(`${purity}`, 22, 6);
  //       doc.setFontSize(5);
  //       // const maxLineLength = 27;
  //       // const descriptionLine1 = description.substring(0, maxLineLength);
  //       // const descriptionLine2 = description.substring(
  //       //   maxLineLength,
  //       //   maxLineLength * 2
  //       // );

  //       // doc.text(descriptionLine1, 4, 10);
  //       // doc.text(descriptionLine2, 4, 11.5);
  //     } else {
  //       doc.text(`G.Wt: ${parseFloat(grosswt).toFixed(3)}`, 3, 6);
  //       doc.text(`MRP: ${parseFloat(mrp)}`, 3, 9);
  //       doc.text(`Pcs:${pieces}`, 18, 3);
  //       // doc.text(`${product_No}`, 4, 11.5);
  //       // doc.text(`${product_No}`, 4, 11.5);
  //       doc.text(`${itemCode}`, 3, 3);
  //       doc.text(`${purity}`, 18, 6);
  //     }

  //     try {
  //       // const qrCodeDataUrl = await QRCode.toDataURL(itemCode);
  //       // doc.addImage(qrCodeDataUrl, "JPEG", 3, 3, imageWidth, imageHeight);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }

  //   const pdfData = doc.output("datauristring");
  //   const newWindow = window.open();
  //   newWindow.document.write(
  //     `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
  //   );
  // };
  console.log(selectedFiles, "selectedFiles");
  console.log(selectedFiles, "selectedFiles");
  console.log(selectedFiles, "selectedFiles");
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    let formData = new FormData();

    formData.append("Product_Name", productName);
    formData.append("Category_id", categoryId);
    formData.append("Category_Name", categoryName);
    formData.append("ProductTypeId", parseInt(productTypeId));
    formData.append("CollectionId", parseInt(collectionId));
    formData.append("PartyTypeId", parseInt(partyId));
    // formData.append("Party_Details", partyName);
    formData.append("purity", purityName);
    formData.append("PurityId", purityId);
    formData.append("BoxId", parseInt(boxId));
    formData.append("MRP", parseFloat(mrp));
    formData.append("Quantity", parseInt(quantity));
    formData.append("grosswt", parseFloat(grosswt).toFixed(3));
    formData.append("StoneWeight", stoneWeight);
    formData.append("NetWt", parseFloat(netWt).toFixed(3));
    formData.append("Entryby_Staff_id", parseInt(Entryby_Staff_id));
    formData.append("Product_No", partyName);
    formData.append("Product_Code", productCode);
    formData.append("Pieces", parseInt(pieces));
    formData.append("HUIDCode", huid);
    formData.append("Size", size);
    formData.append("Hallmark", hallmark);
    formData.append("Hallmark_amt", hallmarkAmount);
    formData.append("collection", collectionName);
    formData.append("occasion", occasion);
    formData.append("gender", gender);
    formData.append("description", description);
    formData.append("Making_Fixed_Amt", making_Fixed_Amt);
    formData.append("Making_per_gram", making_per_gram);
    formData.append(
      "Making_Percentage",
      making_Percentage !== "" ? making_Percentage : "0"
    );
    formData.append("Making_Fixed_Wastage", making_Fixed_Wastage);
    formData.append("StoneAmount", stoneAmount);
    formData.append("Featured", featured);
    formData.append("Itemtype", productTypeName);
    formData.append("Product_type", productTypeName);
    formData.append("branchName", branch);
    formData.append("SKU", selectedSkuName);
    // formData.append("BarcodeNumber", "");
    // formData.append("Images", "");
    if (selectedSkuName !== "" || selectedSku.length > 0) {
      formData.append("Images", selectedSku.images);
      console.log("sku Selected");
    } else if (selectedFiles.length > 0) {
      selectedFiles.forEach((file) => {
        formData.append("Images", file);
      });
      console.log("Images Selected");
    } else {
      formData.append("Images", "");
      console.log(" No Images Selected");
    }
    console.log(formData, "formData");
    // formData.append("ImageList1", "");
    // formData.append("ImageList2", "");
    // formData.append("ImageList3", "");
    // formData.append("ImageList4", "");
    // formData.append("ImageList5", "");

    try {
      const response = await fetch(a8, {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        // body: JSON.stringify(formData),
        body: formData,
      });
      const data = await response.json();

      if (response.ok) {
        setAddedProducts(data.data);
        setLoading(false);
        // console.log("added", data);
        const allItemCodes = data.data.map((product) => ({
          ItemCode: product.itemCode,
        }));
        setAllItemCodesArray(allItemCodes);
        setDeleteAll(true);
        setPartyTypeId("");
        setCategory("");
        setProductType("");
        setPurity("");
        setQuantity(1);
        setCollection("");
        setGrosswt(0);
        setNetWt(0);
        setGender("");
        setStoneWeight(0);
        setMRP(0);
        setProductName("");
        setDescription("");
        setSelectedSku([]);
        setSelectedSkuName("");
        setHasUnsavedChanges(true);
        // setHasUnsavedChanges(false);
        // customNavigate("/adminhome");
        scrollToCenter("adminAddBulkStockAddedTitleStatement");
        // setData(data.data);
        // updateImages();
        // alert("added");
      } else {
        // Handle the error if the upload fails
        console.error("Failed to upload the files.");
      }
    } catch (error) {
      // Handle any network or fetch API errors
      console.error("An error occurred:", error);
    }
  };
  const handleSubmitUnlabelled = async (e) => {
    e.preventDefault();
    // console.log("unlabelled");
    setLoading(true);

    let unLabellData = {
      Entryby_Staff_id: parseInt(Entryby_Staff_id),
      Product_No: partyName,
      Product_Name: productName,
      Category_id: categoryId,
      Category_Name: categoryName,
      Pieces: 1,
      fine_perc: `${finePerc}`,
      wastage_perc: `${wastagePerc}`,
      fine_wastage: `${fineWastagePerc}`,
      HUIDCode: "HUIDCode",
      NetWt: parseFloat(netWt).toFixed(3),
      product_type: productTypeName,
      Size: `${size}`,
      grosswt: parseFloat(grosswt).toFixed(3),
      purity: purityName,
      collection: collectionName,
      occasion: occasion,
      quantity: `${quantity}`,
      gender: gender,
      description: description,
      PurityId: purityId,
      ProductTypeId: parseInt(productTypeId),
      PartyTypeId: parseInt(partyId),
      BoxId: parseInt(boxId),
      Making_Fixed_Amt: `${making_Fixed_Amt}`,
      Making_Fixed_Wastage: `${making_Fixed_Wastage}`,
      Making_Percentage:
        `${making_Percentage}` !== "" ? `${making_Percentage}` : "0",
      Making_per_gram: `${making_per_gram}`,
      StoneWeight: `${stoneWeight}`,
      StoneAmount: `${stoneAmount}`,
      Featured: featured,
      Product_Code: "Product_Code",
      MRP: `${parseFloat(mrp)}`,
      ItemType: productTypeName,
      CollectionId: parseInt(collectionId),
      OnlineStatus: "Active",
      DiamondWeight: "DiamondWeight",
      DiamondAmount: "DiamondAmount",
      DiamondSize: "DiamondSize",
      DiamondPeaces: "DiamondPeaces",
      Clarity: "Clarity",
      Certificate: "Certificate",
      DiamondRate: "DiamondRate",
      Colour: "Colour",
      Shape: "Shape",
      SettingType: "SettingType",
      BranchName: branch,
      BoxName: "BoxName",
    };

    let jsonData = JSON.stringify(unLabellData);
    console.log(unLabellData, "unLabellData Bfore Submit");
    try {
      const response = await fetch(a55, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(formData),
        body: jsonData,
      });
      const data = await response.json();
      // console.log(data, "Unlabeldata");
      // console.log(data.data, "Unlabeldata");
      if (data.status == "Success") {
        setAddedProducts([data.data]);
        // console.log("Inside Response");
        setLoading(false);
        console.log("added", data);
        // const allItemCodes = data.data.map((product) => ({
        //   ItemCode: product.itemCode,
        // }));
        // setAllItemCodesArray(allItemCodes);
        setDeleteAll(true);
        setPartyTypeId("");
        setCategory("");
        setProductType("");
        setPurity("");
        setQuantity(1);
        setCollection("");
        setGrosswt(0);
        setNetWt(0);
        setGender("");
        setStoneWeight(0);
        setMRP(0);
        setProductName("");
        setDescription("");
        setSelectedSku([]);
        setSelectedSkuName("");
        setHasUnsavedChanges(true);
        // setStockType("Labelled");
        scrollToCenter("adminAddBulkStockAddedTitleStatement");
        // setData(data.data);
        // updateImages();
        // alert("added");
      } else {
        // Handle the error if the upload fails
        console.error("Failed to upload the files.");
      }
    } catch (error) {
      // Handle any network or fetch API errors
      console.error("An error occurred:", error);
    }
  };
  useEffect(() => {
    // console.log("allItemCodesArray", allItemCodesArray);
  }, [allItemCodesArray]);

  // console.log("allItemCodesArray outside useEffect", allItemCodesArray);
  const handleInputChange = (e, productId, property) => {
    // const barcodeInput = document.getElementById("barcodeNumberInput");
    // barcodeInput.style.setProperty("color", "black");
    const { value } = e.target;
    // setBarCodeAlert(false);
    const updatedProducts = addedProducts.map((product) => {
      if (product.id === productId) {
        // Parse properties to numbers or set them as 0 if the value is empty or invalid
        const grosswt = parseFloat(product.grosswt) || 0;
        const stoneWeight = parseFloat(product.stoneWeight) || 0;
        const netWt = parseFloat(product.netWt) || 0;

        // Update the specific property in the product object
        let updatedProduct = { ...product, [property]: value };

        if (property === "barcodeNumber") {
          // Convert the barcode number to uppercase before doing the comparison
          const barcodeValue = value.toUpperCase();
          updatedProduct.barcodeNumber = barcodeValue; // Set the barcodeNumber property to uppercase

          // Find a matching product in the rifdData array
          const matchingProduct = rifdData.find(
            (item) => item.barcodeNumber === barcodeValue
          );

          if (matchingProduct) {
            updatedProduct.tid = matchingProduct.tid;
          } else {
            // If no matching product found, set 'tid' to null or some default value
            updatedProduct.tid = null; // or any default value you want
            // setBarCodeAlert(true);
          }
        }

        // If 'grosswt' is changed, calculate 'netWt'
        if (property === "grosswt" && !isNaN(value)) {
          updatedProduct.netWt =
            parseFloat(value) - updatedProduct.stoneWeight > 0
              ? (parseFloat(value) - updatedProduct.stoneWeight).toFixed(3)
              : (updatedProduct.grosswt = value);
        }

        // If 'stoneWeight' is changed, calculate 'netWt'
        if (property === "stoneWeight" && !isNaN(value)) {
          updatedProduct.netWt =
            parseFloat(updatedProduct.grosswt) > value
              ? (updatedProduct.grosswt - parseFloat(value)).toFixed(3)
              : ((updatedProduct.grosswt = value),
                (updatedProduct.stoneWeight = value),
                (updatedProduct.netWt = 0));
        }

        // If 'netWt' is changed, calculate 'grosswt' and 'stoneWeight'
        if (property === "netWt" && !isNaN(value)) {
          updatedProduct.grosswt = (parseFloat(value) + stoneWeight).toFixed(3);
          updatedProduct.stoneWeight = (grosswt - parseFloat(value)).toFixed(3);
        }
        if (property === "pieces" && value > 1 && stockType === "Labelled") {
          setPiecesBox(true);
          setProductPiecesEditId(productId);
          handlePiecesChange(value, productId);
        }

        return updatedProduct;
      }
      return product;
    });

    setAddedProducts(updatedProducts);
  };

  // ... (rest of the code)

  const playTimer = () => {
    setTimeout(() => {
      setGoldAlert(false), setBarCodeAlert(false), setImportAlert(false);
    }, 2000);
  };

  const handleEditProducts = async () => {
    setLoading(true);
    console.log(addedProducts, "save:abel");
    try {
      // Validate 'grosswt' for all products
      const hasInvalidGrossWt = addedProducts.some(
        (product) =>
          (product.grosswt === "" && product.category_Name === "Gold") ||
          (parseFloat(product.grosswt) === 0 &&
            product.category_Name === "Gold")
      );

      const hasMissingBarcodeAndTid = addedProducts.some((product) => {
        if (product.barcodeNumber && product.barcodeNumber.length !== 0) {
          // Barcode is not empty or null, so check if tid is missing
          return product.tid === null || product.tid === "";
        }
        // Barcode is either empty or null, so no need to check tid
        return false;
      });

      if (hasInvalidGrossWt) {
        setLoading(false);
        setGoldAlert(true);
        playTimer();
      } else if (hasMissingBarcodeAndTid) {
        setLoading(false);
        setBarCodeAlert(true);
        playTimer();
      } else {
        // Convert grosswt, stoneWeight, and netWt to strings before sending
        const updatedProductsString = addedProducts.map((product) => ({
          ...product,
          grosswt: product.grosswt.toString(),
          stoneWeight: product.stoneWeight.toString(),
          netWt: product.netWt.toString(),
        }));

        // Send the updated products to the edit API endpoint
        const response = await fetch(a31, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedProductsString),
        });
        console.log(updatedProductsString, "updatedProductsStringLabel");
        const rcvdData = await response.json();
        // console.log("rcvdData", rcvdData);
        //       openLabelInNew(rcvdData.data);
        //       console.log("addedProducts", addedProducts);
        //       console.log("updatedProducts", updatedProducts);
        //       // setUpdatedProducts(rcvdData.data);
        //       setLoading(false);
        //     }
        //   } catch (error) {
        //     alert(error);
        //     console.error(error);
        //     setLoading(false);
        //   }
        // };
        if (rcvdData.status === "error") {
          setLoading(false);
          alert(rcvdData.message); // Show error message
          const productsWithErrors = addedProducts.map((product) =>
            product.barcodeNumber === rcvdData.errorBarcode
              ? { ...product, hasError: true }
              : product
          );
          setAddedProducts(productsWithErrors);
          // console.log("rcvdDataErrorAdded", addedProducts);
        } else {
          openLabelInNew(rcvdData.data);
          setHasUnsavedChanges(false);
          // console.log("addedProducts", addedProducts);
          // console.log("updatedProducts", updatedProducts);
          setLoading(false);
        }
      }
    } catch (error) {
      alert(error);
      console.error(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  const handleEditProductsUnlabelled = async () => {
    setLoading(true);
    console.log(addedProducts, "save");
    try {
      const updatedProductsString = addedProducts.map((product) => ({
        ...product,
        grosswt: product.grosswt.toString(),
        stoneWeight: product.stoneWeight.toString(),
        netWt: product.netWt.toString(),
        mrp: product.mrp.toString(),
        pieces: parseInt(product.pieces),
      }));
      const updatedProductsString2 = updatedProductsString.map((product) => {
        // Filter out properties with null values
        const filteredProduct = Object.fromEntries(
          Object.entries(product).filter(([key, value]) => value !== null)
        );
        return filteredProduct;
      });
      console.log(updatedProductsString2[0], "updatedProductsString");
      // Send the updated products to the edit API endpoint
      const response = await fetch(a57, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedProductsString2[0]),
      });
      const rcvdData = await response.json();
      console.log("rcvdData", rcvdData);
      setHasUnsavedChanges(true);
      setStockType("Labelled");
      setAddedProducts([rcvdData.data]);
      setAddedProducts([]);
      alert("Unlabelled Stock Added");
      scrollToCenter("addBulkProductsBoxTop");
      //       openLabelInNew(rcvdData.data);
      //       console.log("addedProducts", addedProducts);
      //       console.log("updatedProducts", updatedProducts);
      //       // setUpdatedProducts(rcvdData.data);
      //       setLoading(false);
      //     }
      //   } catch (error) {
      //     alert(error);
      //     console.error(error);
      //     setLoading(false);
      //   }
      // };
      if (rcvdData.status === "error") {
        setLoading(false);
        alert(rcvdData.message); // Show error message
      } else {
        // console.log("updatedProducts", updatedProducts);
        setLoading(false);
      }
    } catch (error) {
      alert(error);
      console.error(error);
      setLoading(false);
    }
  };
  // console.log("2addedProducts", addedProducts);
  const filteredProducts = productTypeData.filter(
    (product) => product.category_id == categoryId
  );
  const filteredCollection = collectionTypeData.filter(
    (product) => product.productType == productTypeName
  );
  const filteredPurity = purityData.filter(
    (product) => product.category == categoryName
  );
  const filteredBoxes = boxData.filter(
    (product) => product.productName == productTypeName
  );

  const handleCheckboxChange = (productId, itemCode) => {
    let updatedCheckedProducts = [...checkedProducts];
    let updatedSelectedItemCodes = [...selectedItemCodes];

    if (updatedCheckedProducts.includes(productId)) {
      updatedCheckedProducts = updatedCheckedProducts.filter(
        (id) => id !== productId
      );
      updatedSelectedItemCodes = updatedSelectedItemCodes.filter(
        (code) => code !== itemCode
      );
    } else {
      updatedCheckedProducts.push(productId);
      updatedSelectedItemCodes.push(itemCode);
    }

    if (updatedCheckedProducts.length > 0) {
      setDeleteSelected(true);
    } else {
      setDeleteSelected(false);
    }

    setCheckedProducts(updatedCheckedProducts);
    setSelectedItemCodes(updatedSelectedItemCodes);
  };

  const selectedItems = selectedItemCodes.map((itemCode) => ({
    ItemCode: itemCode,
  }));

  // console.log("checkedProducts", checkedProducts);
  // console.log("selectedItemCodes", selectedItemCodes);
  // console.log("selectedItems", selectedItems);

  const deleteAllProducts = async (itemsToDelete) => {
    try {
      const response = await fetch(a47, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(itemsToDelete),
      });

      const rcvdData = await response.json();
      // console.log("AllItemsDeleted", rcvdData);
      // console.log("Responsee:", rcvdData.message);
      if (rcvdData.status == "Success") {
        // Deletion was successful
        // console.log("Item deleted successfully:", response.message);
        alert(rcvdData.message);
        setSelectedItemCodes([]);
        setCheckedProducts([]);
        scrollToCenter("addBulkProductsBoxTop");
        // You can show an alert or notification here
        // alert(data.message);
        if (itemsToDelete == allItemCodesArray) {
          setAddedProducts([]);
        } else {
          const updatedAddedProducts = addedProducts.filter((product) => {
            return !itemsToDelete.some(
              (item) => item.ItemCode === product.itemCode
            );
          });
          setAddedProducts(updatedAddedProducts);
        }
        setDeleteAll(false);
        setDeleteSelected(false);
      } else {
        // Handle the case where deletion failed
        console.error("Failed to delete item:", response.message);

        // You can show an error message to the user
        alert("Failed to delete item: " + response.message);
      }
    } catch (error) {
      // Handle any network or fetch API errors
      console.error("An error occurred:", error);

      // Show an error message to the user
      // alert("An error occurred while deleting the item.");
    }
  };
  const showAllInputs = () => {
    setHalfInputs(!halfInputs);
    const allFields = document.getElementById("bulkStockAddProductDetailsBox");
    if (halfInputs) {
      allFields.classList.add("bulkStockAddProductDetailsBoxHalfHeight");
      allFields.classList.remove("bulkStockAddProductDetailsBoxFullHeight");
    } else {
      allFields.classList.remove("bulkStockAddProductDetailsBoxHalfHeight");
      allFields.classList.add("bulkStockAddProductDetailsBoxFullHeight");
    }
  };
  const [selectedSku, setSelectedSku] = useState([]);
  const [selectedSkuName, setSelectedSkuName] = useState("");
  const handleSkuInputChange = (e) => {
    const { value } = e.target;
    setSelectedSkuName(value);
    let selectedSkuItem = [];
    selectedSkuItem = allSku.find((x) => x.stockKeepingUnit == value);
    setSelectedSku(selectedSkuItem);
  };
  useEffect(() => {
    if (selectedSkuName !== "" && selectedSku) {
      setDescription(selectedSku.description);
      setNetWt(selectedSku.netWt);
      setBoxId(1);
      // categoryName = selectedSku.category;
      // productTypeName = selectedSku.productType;
      // collectionName = selectedSku.collection;
      // purityName = selectedSku.purity;
      setCategory(`${selectedSku.categoryId},${selectedSku.category}`);
      setProductType(`${selectedSku.productTypeId},${selectedSku.productType}`);
      setCollection(`${selectedSku.collectionId},${selectedSku.collection}`);
      setPurity(`${selectedSku.purityId},${selectedSku.purity}`);
      // categoryId = selectedSku.categoryId;
      // productTypeId = selectedSku.productTypeId;
      // purityId = selectedSku.purityId;
      // collectionId = selectedSku.collectionId;
      setSize(selectedSku.size);
      setGrosswt(selectedSku.grossWt);
      setNetWt(selectedSku.netWt);
      setStoneWeight(selectedSku.totalStoneWt);
      setSelectedFiles(selectedSku.images);
      // setSelectedFiles(selectedSku.images);
      setMaking_Percentage(selectedSku.makingPercentage);
      setMaking_Fixed_Amt(selectedSku.makingFixedAmt);
      setMaking_per_gram(selectedSku.makingPerGram);
      setMaking_Fixed_Wastage(selectedSku.makingFixedWastage);
      setMRP(selectedSku.mrp);
    } else {
      setDeleteAll(true);
      setPartyTypeId("");
      setCategory("");
      setProductType("");
      setPurity("");
      setQuantity(1);
      setCollection("");
      setGrosswt(0);
      setNetWt(0);
      setGender("");
      setStoneWeight(0);
      setMRP(0);
      setBoxId(1);
      setProductName("");
      setDescription("");
      setSelectedFiles([]);
    }
  }, [selectedSku]);

  const uploadExcelFile = async () => {
    if (importFile) {
      const formData = new FormData();
      formData.append("file", importFile);

      fetch(a90, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => ({
              status: response.status,
              data,
            }));
          } else {
            return response.text().then((text) => ({
              status: response.status,
              data: text,
            }));
          }
        })
        .then(({ status, data }) => {
          console.log("Success:", data);
          alert("Imported Data");
          setShowImportPopup(false);
          // Handle success response
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle errors
        });
    } else {
      alert("Please select a file.");
    }
  };

  console.log(importFile, "importFile");
  console.log(importFile, "importFile");
  console.log(importFile, "importFile");
  return (
    <div>
      <div>
        <AdminHeading />
        <div className="adminMainBodyBox" id="addBulkProductsBoxTop">
          <AdminBreadCrump
            title={"Add Bulk Stock"}
            companyName={"Loyalstring"}
            module={"E-commerce"}
            page={"Add Bulk Stock"}
          />
          <div className="adminAddCategoryMainBox2">
            <div
              style={{
                margin: "0px",
                padding: "0px",
                backgroundColor: "transparent",
              }}
              className="adminAddCategoryInnerBox2"
            >
              {goldAlert
                ? // <AlertMessage
                  //   type="error"
                  //   message="Gross Wt of Gold could not be zero"
                  // />
                  alert("Gross Wt of Gold could not be zero")
                : null}
              {barCodeAlert
                ? // <AlertMessage
                  //   type="error"
                  //   message="Sorry, Please enter a correct Barcode"
                  // />
                  alert("Sorry, Please enter a correct Barcode")
                : null}

              {piecesBox ? (
                <div className="adminInvoiceOpenEditMainBox adminAddBulkStockMultiplePiecesMainBox">
                  <div className="adminInvoiceOpenEditInnerBox">
                    <div className="adminInvoiceOpenEditInnerTitleBox">
                      <button
                        onClick={() => closePiecesEditBox()}
                        className="adminAddInvoiceMainAddLabelOptionDeleteIcon"
                      >
                        close
                      </button>
                    </div>
                    <div className="adminInvoiceOpenEditOuterGridBox">
                      {barcodeNumbersArray.map((item, index) => {
                        const barcodeNumberKey = Object.keys(item)[0]; // Get the barcodeNumber key
                        const tidValue = item[barcodeNumberKey]; // Get the tid value

                        return (
                          <>
                            <div
                              className="adminInvoiceOpenEditInnerGridItem"
                              key={index}
                            >
                              <label>Barcode Number</label>
                              <input
                                type="text"
                                value={barcodeNumberKey}
                                onChange={(e) =>
                                  handleBarcodeNumberChange(
                                    e.target.value.toUpperCase(),
                                    index
                                  )
                                }
                              />
                            </div>
                            <div
                              className="adminInvoiceOpenEditInnerGridItem"
                              key={index}
                            >
                              <label>Tid</label>
                              <input type="text" value={tidValue} readOnly />
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <button
                      onClick={() => {
                        handleCheckTidValues();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              ) : null}

              <div>
                {loadingAdd ? (
                  <div className="adminAddCategoryMainbox addProductMain">
                    <form
                      style={{
                        marginTop: "10px",
                      }}
                      onSubmit={
                        stockType === "Labelled"
                          ? handleSubmit
                          : handleSubmitUnlabelled
                      }
                    >
                      <h3
                        className="adminAddBulkStockAddedTitle"
                        style={{ marginBottom: "3rem", width: "97%" }}
                      >
                        ADD BULK STOCK
                      </h3>

                      {/* <h4
                        id="adminInvoiceAddedCustomerEdit"
                        className="adminInvoiceAddTitles"
                        style={{
                          marginBottom: "3rem",
                          width: "95%",
                          fontSize: "16px",
                          padding: "15px 10px",
                        }}
                      >
                        Add Product
                      </h4>{" "} */}
                      <div className="addProductDetailsUpperBox">
                        <div
                          // style={{ width: "92%" }}
                          className="addProductDetailsBox"
                        >
                          <label htmlFor="category">
                            <strong>SUPPLIER</strong>
                          </label>
                          <select
                            id="category"
                            required="required"
                            value={partyTypeId}
                            onChange={(e) => setPartyTypeId(e.target.value)}
                          >
                            <option value="">
                              Select Party / Karigar Name
                            </option>
                            {partyData.map((x, y) => {
                              return (
                                <option
                                  key={y}
                                  value={`${parseInt(x.id)},${x.supplier_code}`}
                                >
                                  {x.supplier_name}
                                </option>
                              );
                            })}
                          </select>
                          <p>Unlabelled Gold :</p>

                          <div>
                            {(() => {
                              const selectedParty = partyData.find(
                                (x) => x.id === parseInt(partyTypeId)
                              );
                              if (selectedParty) {
                                return (
                                  <div
                                    className="addProductSupplierDetailsBox"
                                    key={selectedParty.id}
                                  >
                                    {" "}
                                    {/* It's good practice to include a key even if it's not strictly necessary here */}
                                    <p>{selectedParty.inwardGold}</p>
                                  </div>
                                );
                              }
                              return null; // If no party is found, render nothing
                            })()}
                          </div>
                        </div>
                        <div
                          // style={{ width: "92%" }}
                          className="addProductDetailsBox"
                        >
                          <label htmlFor="sku">
                            <strong>SKU</strong>
                          </label>
                          <input
                            // style={{ width: "30vw" }}
                            type="text"
                            name="skuList"
                            placeholder="Enter SKU"
                            value={selectedSkuName}
                            onInput={handleSkuInputChange}
                            list="skuList"
                          />
                          <datalist id="skuList">
                            {allSku.map((sku, index) => (
                              <option
                                key={index}
                                value={`${sku.stockKeepingUnit}`}
                              />
                            ))}
                          </datalist>
                          <p>Unlabelled Silver :</p>
                          <div>
                            {(() => {
                              const selectedParty = partyData.find(
                                (x) => x.id === parseInt(partyTypeId)
                              );
                              if (selectedParty) {
                                return (
                                  <div
                                    className="addProductSupplierDetailsBox"
                                    key={selectedParty.id}
                                  >
                                    {" "}
                                    {/* It's good practice to include a key even if it's not strictly necessary here */}
                                    <p>{selectedParty.inwardSilver}</p>
                                  </div>
                                );
                              }
                              return null; // If no party is found, render nothing
                            })()}
                          </div>
                        </div>
                        <div
                          // style={{ width: "92%" }}
                          className="addProductDetailsBox"
                        >
                          <label htmlFor="invoiceType">
                            <strong>STOCK TYPE</strong>
                          </label>
                          <select
                            id="invoiceType"
                            required="required"
                            value={stockType}
                            onChange={(e) => setStockType(e.target.value)}
                          >
                            <option value="Labelled">Labelled</option>
                            <option value="Unlabelled">Unlabelled</option>
                          </select>
                          <p>Unlabelled Diamond :</p>
                          <div>
                            {(() => {
                              const selectedParty = partyData.find(
                                (x) => x.id === parseInt(partyTypeId)
                              );
                              if (selectedParty) {
                                return (
                                  <div
                                    className="addProductSupplierDetailsBox"
                                    key={selectedParty.id}
                                  >
                                    {" "}
                                    {/* It's good practice to include a key even if it's not strictly necessary here */}
                                    <p>{selectedParty.supplier_name}</p>
                                  </div>
                                );
                              }
                              return null; // If no party is found, render nothing
                            })()}
                          </div>
                        </div>
                        <div
                          style={
                            {
                              // width: "92%",
                              // marginBottom: "20px",
                            }
                          }
                          className="addProductDetailsBox"
                        >
                          <label htmlFor="selectBranch">
                            <strong>SELECT BRANCH</strong>
                          </label>
                          <select
                            id="selectBranch"
                            required="required"
                            value={branch}
                            onChange={(e) => setBranch(e.target.value)}
                          >
                            <option value="Home">Home</option>
                            <option value="Branch 2">Branch 2</option>
                          </select>

                          <p>Unlabelled Other :</p>
                          <div>
                            {(() => {
                              const selectedParty = partyData.find(
                                (x) => x.id === parseInt(partyTypeId)
                              );
                              if (selectedParty) {
                                return (
                                  <div
                                    className="addProductSupplierDetailsBox"
                                    key={selectedParty.id}
                                  >
                                    {" "}
                                    {/* It's good practice to include a key even if it's not strictly necessary here */}
                                    <p>{selectedParty.supplier_name}</p>
                                  </div>
                                );
                              }
                              return null; // If no party is found, render nothing
                            })()}
                          </div>
                        </div>
                      </div>
                      <h4
                        style={{ width: "95%", marginTop: "30px" }}
                        id="adminInvoiceAddedCustomerEdit"
                        className="adminInvoiceAddTitles"
                      >
                        Add Items
                      </h4>
                      {/* <div
                        style={{
                          display: "flex",
                          cursor: "pointer",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                        className="adminAddBulkStockShowEditButton"
                        onClick={() => showAllInputs()}
                      >
                        <AiOutlineEdit size={"20px"} />
                      </div> */}
                      <div
                        // className="bulkStockAddProductDetailsBox bulkStockAddProductDetailsBoxHalfHeight"
                        className="bulkStockAddProductDetailsBox"
                        id="bulkStockAddProductDetailsBox"
                      >
                        <div className="bulkStockAddProductDetailsItem">
                          <label style={{ margin: 0 }}>Category</label>
                          <select
                            id="category"
                            required="required"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            <option value="">Category</option>
                            {categoriesData.map((x, y) => {
                              return (
                                <option key={y} value={`${x.id},${x.name}`}>
                                  {x.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="bulkStockAddProductDetailsItem">
                          <label htmlFor="productTypeId" style={{ margin: 0 }}>
                            Product
                          </label>

                          <select
                            id="productTypeId"
                            required="required"
                            value={productType}
                            onChange={(e) => setProductType(e.target.value)}
                          >
                            <option value="">Product Type</option>
                            {filteredProducts.map((x, y) => {
                              return (
                                <option
                                  key={y}
                                  value={`${parseInt(x.id)},${x.productTitle}`}
                                >
                                  {x.productTitle}
                                </option>
                              );
                            })}
                            {/* {productTypeData.map((x) => {
                                return (
                                  // <option value={parseInt(x.id)}>{x.productTitle}</option>
                                  <option value={`${parseInt(x.id)},${x.productTitle}`}>
                                    {x.productTitle}
                                  </option>
                                );
                              })} */}
                          </select>
                        </div>
                        <div className="bulkStockAddProductDetailsItem">
                          <label style={{ margin: 0 }}>Collection</label>
                          <select
                            id="collection"
                            required="required"
                            value={collection}
                            onChange={(e) => setCollection(e.target.value)}
                          >
                            <option value="">Colletion</option>
                            {filteredCollection.map((x, y) => {
                              return (
                                <option
                                  key={y}
                                  value={`${parseInt(x.id)},${
                                    x.collection_Name
                                  }`}
                                >
                                  {x.collection_Name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="bulkStockAddProductDetailsItem">
                          <label style={{ margin: 0 }}>Purity</label>
                          <select
                            id="purity"
                            required="required"
                            value={purity}
                            onChange={(e) => setPurity(e.target.value)}
                          >
                            <option value="">Purity</option>
                            {filteredPurity.map((x, y) => {
                              return (
                                <option
                                  key={y}
                                  value={`${parseInt(x.id)},${x.label}`}
                                >
                                  {x.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        {stockType === "Labelled" ? (
                          <div className="bulkStockAddProductDetailsItem">
                            <label htmlFor="boxId" style={{ margin: 0 }}>
                              Box
                            </label>
                            <select
                              id="boxId"
                              // required="required"
                              value={boxId}
                              onChange={(e) => setBoxId(e.target.value)}
                            >
                              <option value="">Box</option>
                              {filteredBoxes.map((x, y) => {
                                return (
                                  <option key={y} value={parseInt(x.id)}>
                                    {x.boxName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        ) : null}
                        <div className="bulkStockAddProductDetailsItem">
                          <label style={{ margin: 0 }}>Quantity</label>
                          <input
                            required="required"
                            type="number"
                            value={quantity}
                            onChange={(e) => {
                              setQuantity(e.target.value);
                            }}
                          />
                        </div>
                        {/* <div className="bulkStockAddProductDetailsItem">
                                <label style={{ margin: 0 }}>P Name</label>
                                <input
                                  type="text"
                                  required="required"
                                  value={productName}
                                  onChange={(e) => {
                                    setProductName(e.target.value);
                                  }}
                                />
                              </div> */}

                        <div className="bulkStockAddProductDetailsItem">
                          <label htmlFor="grosswt" style={{ margin: 0 }}>
                            G.Wt
                          </label>
                          {/* <select
                          id="category"
                          required="required"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option value="">G.Wt</option>
                        </select> */}
                          <input
                            type="number"
                            id="grosswt"
                            required="required"
                            value={grosswt}
                            onChange={(e) => {
                              setGrosswt(e.target.value),
                                e.target.value - parseFloat(stoneWeight) > 0
                                  ? setNetWt(
                                      parseFloat(e.target.value) -
                                        parseFloat(stoneWeight)
                                    )
                                  : (setNetWt(0), setStoneWeight(0));
                            }}
                          />
                        </div>
                        <div className="bulkStockAddProductDetailsItem">
                          <label htmlFor="stoneWeight" style={{ margin: 0 }}>
                            St.Wt
                          </label>
                          {/* <select
                              id="category"
                              required="required"
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                            >
                              <option value="">St.Wt</option>
                            </select> */}
                          <input
                            type="number"
                            id="stoneWeight"
                            value={stoneWeight}
                            onChange={(e) => {
                              e.target.value <= parseFloat(grosswt)
                                ? (setStoneWeight(e.target.value),
                                  setNetWt(
                                    (
                                      parseFloat(grosswt) -
                                      parseFloat(e.target.value)
                                    ).toFixed(3)
                                  ))
                                : null;
                              // setGrosswt(
                              //   parseFloat(netWt) + parseFloat(e.target.value)
                              // );
                            }}
                          />
                        </div>
                        <div className="bulkStockAddProductDetailsItem">
                          <label htmlFor="netWt" style={{ margin: 0 }}>
                            Net.Wt
                          </label>
                          {/* <select
                            id="category"
                            required="required"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            <option value="">Net.Wt</option>
                          </select> */}
                          <input
                            type="number"
                            id="netWt"
                            value={netWt}
                            readOnly
                            // onChange={(e) => {
                            //   setNetWt(e.target.value),
                            //     setGrosswt(
                            //       parseFloat(e.target.value) +
                            //         parseFloat(stoneWeight)
                            //     );
                            // }}
                          />
                        </div>
                        {stockType !== "Labelled" ? (
                          <div className="bulkStockAddProductDetailsItem">
                            <label style={{ margin: 0 }}>Fine%</label>
                            <input
                              type="number"
                              value={finePerc}
                              onChange={(e) => {
                                setFinePerc(e.target.value),
                                  setFineWastagePerc(e.target.value);
                              }}
                            />
                          </div>
                        ) : null}
                        {stockType !== "Labelled" ? (
                          <div className="bulkStockAddProductDetailsItem">
                            <label style={{ margin: 0 }}>Wastage%</label>
                            <input
                              type="number"
                              value={wastagePerc}
                              onChange={(e) => {
                                setWastagePerc(e.target.value),
                                  setFineWastagePerc(
                                    parseFloat(finePerc) +
                                      parseFloat(e.target.value)
                                  );
                              }}
                            />
                          </div>
                        ) : null}
                        {stockType !== "Labelled" ? (
                          <div className="bulkStockAddProductDetailsItem">
                            <label style={{ margin: 0 }}>Fine+Wastage%</label>
                            <input
                              type="number"
                              value={fineWastagePerc}
                              onChange={(e) => {
                                setFineWastagePerc(e.target.value);
                              }}
                            />
                          </div>
                        ) : null}
                        {stockType === "Labelled" ? (
                          <div className="bulkStockAddProductDetailsItem">
                            <label style={{ margin: 0 }}>MRP</label>
                            <input
                              type="number"
                              value={mrp}
                              onChange={(e) => {
                                setMRP(e.target.value);
                              }}
                            />
                          </div>
                        ) : null}
                        {stockType === "Labelled" ? (
                          <div className="bulkStockAddProductDetailsItem">
                            <label htmlFor="name" style={{ margin: 0 }}>
                              P.Name
                            </label>
                            <input
                              type="text"
                              id="name"
                              value={productName}
                              onChange={(e) => setProductName(e.target.value)}
                            />
                          </div>
                        ) : null}
                        {stockType === "Labelled" ? (
                          <div className="bulkStockAddProductDetailsItem">
                            <label htmlFor="description" style={{ margin: 0 }}>
                              P.Description
                            </label>
                            <input
                              style={{ width: "2fr" }}
                              type="text"
                              id="description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </div>
                        ) : null}
                        {stockType === "Labelled" ? (
                          <div className="bulkStockAddProductDetailsItem">
                            <label style={{ margin: 0 }}>Gender</label>
                            <select
                              value={gender}
                              onChange={(e) => setGender(e.target.value)}
                            >
                              <option value="">Select an option</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Unisex">Unisex</option>
                              <option value="Kids">Kids</option>
                            </select>
                          </div>
                        ) : null}
                        {stockType === "Labelled" ? (
                          <div className="bulkStockAddProductDetailsItem">
                            <label htmlFor="name" style={{ margin: 0 }}>
                              Making-Percentage
                            </label>
                            <input
                              type="text"
                              id="name"
                              value={making_Percentage}
                              onChange={(e) =>
                                setMaking_Percentage(e.target.value)
                              }
                            />
                          </div>
                        ) : null}
                        {stockType === "Labelled" ? (
                          <div className="bulkStockAddProductDetailsItem">
                            <label style={{ margin: 0, cursor: "pointer" }}>
                              {/* Images {`${selectedFiles.length}`} */}
                              <BsImages
                                className="bulkStockAddProductAddImagesIcon"
                                style={{ marginInline: "1rem" }}
                                size={"2.5rem"}
                              />
                              <input
                                id="images"
                                style={{ display: "none" }}
                                type="file"
                                multiple
                                onChange={handleFileInputChange}
                              />
                            </label>
                            <label> Images {`${selectedFiles.length}`}</label>
                          </div>
                        ) : null}
                        {addedProducts.length <= 0 ? (
                          <div
                            style={{
                              justifyContent: "left",
                              marginTop: "20px",
                            }}
                            className="bulkStockAddProductDetailsItem"
                          >
                            {/* <label>Add Product</label> */}
                            <button
                              type="submit"
                              style={{ width: "100px", marginRight: "10px" }}
                            >
                              <IoIosAddCircleOutline
                                style={{ marginRight: "10px" }}
                                size={"20px"}
                              />
                              Add
                            </button>

                            <button
                              id="bulkStockAddProductImportButton"
                              type="button"
                              onClick={() =>
                                setShowImportPopup(!showImportPopup)
                              }
                              style={{ width: "100px", marginInline: "0" }}
                            >
                              <CiImport
                                style={{ marginRight: "10px" }}
                                size={"20px"}
                              />
                              Import
                            </button>
                            {/* </div> */}
                          </div>
                        ) : null}
                      </div>
                    </form>
                    {showImportPopup && (
                      <div className="popup">
                        <div className="popup-inner">
                          <div className="adminAddProductsPopupInnerBox">
                            <input
                              onChange={(e) => setImportFile(e.target.files[0])} // Adjusted to handle file
                              type="file"
                              accept=".xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv" // Now accepts CSV files as well
                            />
                            <button
                              id="bulkStockAddProductImportButton"
                              onClick={() =>
                                importFile && importFile instanceof File
                                  ? uploadExcelFile()
                                  : alert("Please Select a file")
                              }
                              className="close-btn"
                            >
                              Import
                            </button>
                            <button
                              onClick={() => setShowImportPopup(false)}
                              className="bulkProductAddDeleteButton close-btn"
                            >
                              Close
                            </button>
                          </div>
                          {/* <p>This is a popup screen!</p> */}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    style={{ height: "50vh", marginBottom: "1rem" }}
                    // className={loadingAdd == true ? "loading" : "none"}
                    className="loading"
                  >
                    <InfinitySpin
                      // className={loadingAdd == true ? "loading" : "none"}
                      className="loading"
                      width="150"
                      color="#4fa94d"
                    />
                  </div>
                )}
                {/* <div>
          <h1>Table Component</h1>
          <table style={{ margin: "3rem" }}>
            <thead>
              <tr>
                <th>ID</th>
                <th>P Name</th>
                <th>Gross Wt</th>
                <th>Stone Wt</th>
                <th>Net Wt</th>
              </tr>
            </thead>
             <tbody>{renderTableRows()}</tbody> 
          </table>
        </div> */}
                <div
                  style={{ height: "100px", marginBottom: "1rem" }}
                  className={loading == true ? "loading" : "none"}
                >
                  <InfinitySpin
                    className={loading == true ? "loading" : "none"}
                    width="150"
                    color="#4fa94d"
                  />
                </div>
                <div
                  id="adminAddBulkStockAddedTitleStatement"
                  className="adminAddBulkStockShowEditBox"
                >
                  <h3
                    style={{
                      margin: "0px",
                      padding: "0px",
                    }}
                    className="adminAddBulkStockAddedTitle"
                  >
                    Added Products
                  </h3>
                  <div className="adminAddBulkStockShowEditButton">
                    <AiOutlineEdit
                      onClick={() => setShowAllFields(!showAllFields)}
                      size={"20px"}
                    />
                  </div>
                </div>
                <div className="adminAddBulkStockAddedProductsOuterBox">
                  {/* <form onSubmit={updatedetailsBox}> */}
                  {showAllFields ? (
                    <div
                      className="bulkProductAddingTableMain"
                      style={{ margin: "1.5rem", overflowX: "auto" }}
                    >
                      <table>
                        {stockType === "Labelled" ? (
                          <thead>
                            <tr style={{ whiteSpace: "nowrap" }}>
                              <th>Product Type</th>
                              <th>Collection</th>
                              <th>Purity</th>
                              <th>Label</th>
                              <th>Barcode Number</th>
                              <th>TID</th>
                              <th>Product name</th>
                              <th>HUID Code</th>
                              <th>Hallmark Amount</th>
                              <th>GrossWt</th>
                              <th>StoneWt</th>
                              <th>NetWt</th>
                              <th>Stone Amount</th>
                              <th>Making Per Gram</th>
                              <th>Making Percentage</th>
                              <th>Fixed Making</th>
                              <th>Fixed Wastage</th>
                              <th>Pieces</th>
                              <th>Size</th>
                              <th>MRP</th>
                              {/* <th>Hallmark</th> */}
                              <th>Description</th>
                              <th>Occassion</th>
                              <th>Gender</th>
                              <th>Online Status</th>
                              <th>Delete Product</th>
                            </tr>
                          </thead>
                        ) : (
                          <thead>
                            <tr style={{ whiteSpace: "nowrap" }}>
                              <th>Metal</th>
                              <th>Product Type</th>
                              <th>Collection</th>
                              <th>Purity</th>
                              <th>Quantity</th>

                              <th>GrossWt</th>
                              <th>StoneWt</th>
                              <th>NetWt</th>

                              <th>Making Per Gram</th>
                              <th>Making Percentage</th>
                              <th>Fixed Making</th>
                              <th>Fixed Wastage</th>
                              <th>Pieces</th>
                              <th>Size</th>
                              <th>Description</th>
                              <th>Occasion</th>
                              <th>Gender</th>
                              <th>Featured</th>

                              <th>Online Status</th>
                            </tr>
                          </thead>
                        )}
                        <tbody>
                          {addedProducts.map((x) => (
                            // <tr key={x.Customer_id}>

                            <tr key={x.id}>
                              {stockType !== "Labelled" ? (
                                <td>
                                  <input
                                    type="text"
                                    placeholder={x.category_Name}
                                    readOnly
                                    // value={x.product_type}
                                    // onChange={(e) => handleInputChange(e, x.id, "Product_type")}
                                  />
                                </td>
                              ) : null}
                              <td>
                                <input
                                  type="text"
                                  placeholder={x.product_type}
                                  readOnly
                                  // value={x.product_type}
                                  // onChange={(e) => handleInputChange(e, x.id, "Product_type")}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder={x.collection}
                                  value={x.collection}
                                  readOnly
                                  // onChange={(e) => handleInputChange(e, x.id, "collection")}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder={x.purity}
                                  value={x.purity}
                                  readOnly
                                  // onChange={() => {
                                  //   setPurity(x.purity);
                                  // }}
                                />
                              </td>

                              {stockType !== "Labelled" ? (
                                <td>
                                  <input
                                    type="text"
                                    placeholder={x.quantity}
                                    value={x.quantity}
                                    onChange={(e) =>
                                      handleInputChange(e, x.id, "quantity")
                                    }
                                    // onChange={() => {
                                    //   setPurity(x.purity);
                                    // }}
                                  />
                                </td>
                              ) : null}
                              {stockType === "Labelled" ? (
                                <td>
                                  <input
                                    type="text"
                                    placeholder={x.itemCode}
                                    value={x.itemCode}
                                    //   onChange={() => {
                                    //     setItemCode(x.itemCode);
                                    //   }}
                                  />
                                </td>
                              ) : null}
                              {stockType === "Labelled" ? (
                                <td>
                                  <input
                                    id="barcodeNumberInput"
                                    type="text"
                                    placeholder={x.barcodeNumber}
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        x.id,
                                        "barcodeNumber"
                                      )
                                    }
                                    style={{
                                      color: x.hasError ? "red" : "black",
                                    }}
                                    //     setItemCode(x.itemCode);
                                    //   }}
                                  />
                                </td>
                              ) : null}
                              {stockType === "Labelled" ? (
                                <td>
                                  <input
                                    style={{ cursor: "not-allowed" }}
                                    type="text"
                                    placeholder={x.tid}
                                  />
                                </td>
                              ) : null}
                              {stockType === "Labelled" ? (
                                <td>
                                  <input
                                    type="text"
                                    placeholder={x.product_Name}
                                    value={x.product_Name}
                                    onChange={(e) =>
                                      handleInputChange(e, x.id, "product_Name")
                                    }
                                  />
                                </td>
                              ) : null}
                              {stockType === "Labelled" ? (
                                <td>
                                  <input
                                    type="text"
                                    maxLength={6}
                                    placeholder={x.huidCode}
                                    value={x.huidCode}
                                    onChange={(e) =>
                                      handleInputChange(e, x.id, "huidCode")
                                    }
                                  />
                                </td>
                              ) : null}
                              {stockType === "Labelled" ? (
                                <td>
                                  <input
                                    type="number"
                                    placeholder={x.hallmark_amt}
                                    value={x.hallmark_amt}
                                    onChange={(e) =>
                                      handleInputChange(e, x.id, "hallmark_amt")
                                    }
                                  />
                                </td>
                              ) : null}
                              <td>
                                <input
                                  type="number"
                                  placeholder={x.grosswt}
                                  value={x.grosswt}
                                  onChange={(e) =>
                                    handleInputChange(e, x.id, "grosswt")
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  placeholder={x.stoneWeight}
                                  value={x.stoneWeight}
                                  onChange={(e) =>
                                    handleInputChange(e, x.id, "stoneWeight")
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  placeholder={x.netWt}
                                  value={x.netWt}
                                  // onChange={(e) =>
                                  //   handleInputChange(e, x.id, "netWt")
                                  // }
                                  readOnly
                                />
                              </td>
                              {stockType === "Labelled" ? (
                                <td>
                                  <input
                                    type="number"
                                    placeholder={x.stoneAmount}
                                    value={x.stoneAmount}
                                    onChange={(e) =>
                                      handleInputChange(e, x.id, "stoneAmount")
                                    }
                                  />
                                </td>
                              ) : null}
                              <td>
                                <input
                                  type="number"
                                  placeholder={x.making_per_gram}
                                  value={x.making_per_gram}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      x.id,
                                      "making_per_gram"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  placeholder={x.making_Percentage}
                                  value={x.making_Percentage}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      x.id,
                                      "making_Percentage"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  placeholder={x.making_Fixed_Amt}
                                  value={x.making_Fixed_Amt}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      x.id,
                                      "making_Fixed_Amt"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  placeholder={x.making_Fixed_Wastage}
                                  value={x.making_Fixed_Wastage}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      x.id,
                                      "making_Fixed_Wastage"
                                    )
                                  }
                                />
                              </td>
                              {/* <td>
                                <input
                                  type="number"
                                  placeholder={x.pieces}
                                  onChange={(e) =>
                                    handleInputChange(e, x.id, "pieces")
                                  }
                                />
                              </td> */}
                              <td>
                                <input
                                  type="number"
                                  value={x.pieces}
                                  onChange={(e) =>
                                    handleInputChange(e, x.id, "pieces")
                                  }
                                />
                              </td>

                              <td>
                                <input
                                  type="number"
                                  placeholder={x.size}
                                  value={x.size}
                                  onChange={(e) =>
                                    handleInputChange(e, x.id, "size")
                                  }
                                />
                              </td>
                              {stockType === "Labelled" ? (
                                <td>
                                  <input
                                    type="number"
                                    placeholder={x.mrp}
                                    value={x.mrp}
                                    onChange={(e) =>
                                      handleInputChange(e, x.id, "mrp")
                                    }
                                  />
                                </td>
                              ) : null}
                              {/* {stockType === "Labelled" ? (
                                <td>
                                  <input
                                    type="text"
                                    placeholder={x.hallmark}
                                    onChange={(e) =>
                                      handleInputChange(e, x.id, "hallmark")
                                    }
                                  />
                                </td>
                              ) : null} */}

                              <td>
                                <input
                                  type="text"
                                  placeholder={x.description}
                                  value={x.description}
                                  onChange={(e) =>
                                    handleInputChange(e, x.id, "description")
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder={x.occasion}
                                  value={x.occasion}
                                  onChange={(e) =>
                                    handleInputChange(e, x.id, "occasion")
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder={x.gender}
                                  onChange={(e) =>
                                    handleInputChange(e, x.id, "gender")
                                  }
                                />
                              </td>
                              {stockType !== "Labelled" ? (
                                <td>
                                  <input
                                    type="text"
                                    placeholder={x.onlineStatus}
                                    value={x.onlineStatus}
                                    readOnly
                                  />
                                </td>
                              ) : null}
                              <td>
                                <input
                                  type="text"
                                  placeholder={x.featured}
                                  onChange={(e) =>
                                    handleInputChange(e, x.id, "featured")
                                  }
                                />
                              </td>
                              {stockType === "Labelled" ? (
                                <td>
                                  <input
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      color: "red",
                                    }}
                                    type="checkbox"
                                    checked={checkedProducts.includes(x.id)}
                                    onChange={() =>
                                      handleCheckboxChange(x.id, x.itemCode)
                                    }
                                  />
                                </td>
                              ) : null}
                              {/* <td>
                    <button
                      type="submit"
                      onClick={() => {
                        setItemCode(x.itemCode);
                        setProductInEditImages(x.images);
                        setProductInEdit(x);
                        {
                          console.log(x.id);
                        }
                        updatedetailsBox(x.id);
                      }}
                    >
                      Update
                    </button>
                  </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div>
                      {/* <h2>Half Fields</h2> */}
                      <div
                        className="bulkProductAddingTableMain bulkProductAddingTableMain2"
                        style={{ margin: "1.5rem", overflowX: "auto" }}
                      >
                        <table>
                          <thead>
                            <tr>
                              {/* <th style={{ whiteSpace: "nowrap" }}>
                              Delete Product
                            </th> */}
                              {/* <th>Product Type</th> */}
                              {stockType !== "Labelled" ? <th>Metal</th> : null}
                              <th>Collection</th>
                              <th>Purity</th>
                              {stockType === "Labelled" ? <th>Label</th> : null}
                              {/* <th>Barcode Number</th> */}
                              {/* <th>TID</th> */}
                              {/* <th>Product name</th> */}
                              {/* <th>HUID Code</th> */}
                              <th>GrossWt</th>
                              {/* {stockType !== "Labelled" ? ( */}
                              <th>StoneWt</th>
                              {/* ) : null} */}

                              <th>NetWt</th>
                              {/* <th>Stone Amount</th> */}
                              {stockType === "Labelled" ? (
                                <th>Making Per Gram</th>
                              ) : null}
                              {stockType === "Labelled" ? (
                                <th>Making Percentage</th>
                              ) : null}
                              {stockType === "Labelled" ? (
                                <th>Fixed Making</th>
                              ) : null}
                              {/* <th>Fixed Wastage</th> */}
                              {/* <th>Pieces</th> */}
                              {/* <th>Size</th> */}
                              {/* {stockType === "Labelled" ? <th>MRP</th> : null} */}
                              {stockType !== "Labelled" ? (
                                <th>Quantity</th>
                              ) : null}
                              {stockType !== "Labelled" ? (
                                <th>Pieces</th>
                              ) : null}
                              {/* <th>Description</th> */}
                              {/* <th>Occassion</th> */}
                              {/* <th>Gender</th> */}
                              {/* <th>Online Status</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {addedProducts.map((x) => (
                              // <tr key={x.Customer_id}>

                              <tr key={x.id}>
                                {/* <td>
                                <input
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    color: "red",
                                  }}
                                  type="checkbox"
                                  checked={checkedProducts.includes(x.id)}
                                  onChange={() =>
                                    handleCheckboxChange(x.id, x.itemCode)
                                  }
                                />
                              </td> */}
                                {/* <td>
                                <input
                                  type="text"
                                  placeholder={x.product_type}
                                
                                />
                              </td> */}
                                {stockType !== "Labelled" ? (
                                  <td>
                                    <input
                                      type="text"
                                      placeholder={x.category_Name}
                                      value={x.category_Name}
                                      readOnly
                                    />
                                  </td>
                                ) : null}
                                <td>
                                  <input
                                    type="text"
                                    placeholder={x.collection}
                                    value={x.collection}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    placeholder={x.purity}
                                    value={x.purity}
                                    readOnly
                                  />
                                </td>
                                {stockType === "Labelled" ? (
                                  <td>
                                    <input
                                      type="text"
                                      placeholder={x.itemCode}
                                      value={x.itemCode}
                                      readOnly
                                    />
                                  </td>
                                ) : null}
                                {/* <td>
                                <input
                                  id="barcodeNumberInput"
                                  type="text"
                                  placeholder={x.BarcodeNumber}
                                  onChange={(e) =>
                                    handleInputChange(e, x.id, "barcodeNumber")
                                  }
                                  style={{
                                    color: x.hasError ? "red" : "black",
                                  }}
                                  //     setItemCode(x.itemCode);
                                  //   }}
                                />
                              </td> */}
                                {/* <td>
                                <input
                                  style={{ cursor: "not-allowed" }}
                                  type="text"
                                  placeholder={x.tid}
                                />
                              </td>
                              <td>
                              <input
                              type="text"
                                  placeholder={x.product_Name}
                                  // value={x.product_Name}
                                  onChange={(e) =>
                                    handleInputChange(e, x.id, "Product_Name")
                                  }
                                />
                              </td>
                              <td>
                              <input
                              type="text"
                                  maxLength={6}
                                  placeholder={x.huidCode}
                                  onChange={(e) =>
                                    handleInputChange(e, x.id, "huidCode")
                                  }
                                />
                              </td> */}
                                <td>
                                  <input
                                    type="number"
                                    placeholder={x.grosswt}
                                    value={x.grosswt}
                                    onChange={(e) =>
                                      handleInputChange(e, x.id, "grosswt")
                                    }
                                    // readOnly
                                  />
                                </td>
                                {/* {stockType !== "Labelled" ? ( */}
                                <td>
                                  <input
                                    type="number"
                                    placeholder={x.stoneWeight}
                                    value={x.stoneWeight}
                                    onChange={(e) =>
                                      handleInputChange(e, x.id, "stoneWeight")
                                    }
                                  />
                                </td>
                                {/* ) : null} */}
                                <td>
                                  <input
                                    type="number"
                                    placeholder={x.netWt}
                                    value={x.netWt}
                                    readOnly
                                    // onChange={(e) =>
                                    //   handleInputChange(e, x.id, "netWt")
                                    // }
                                    // readOnly
                                  />
                                </td>

                                {/* <td>
                                <input
                                  type="number"
                                  placeholder={x.stoneAmount}
                                  onChange={(e) =>
                                    handleInputChange(e, x.id, "StoneAmount")
                                  }
                                />
                              </td> */}
                                {stockType === "Labelled" ? (
                                  <td>
                                    <input
                                      type="number"
                                      placeholder={x.making_per_gram}
                                      value={x.making_per_gram}
                                      // readOnly
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          x.id,
                                          "making_per_gram"
                                        )
                                      }
                                    />
                                  </td>
                                ) : null}
                                {stockType === "Labelled" ? (
                                  <td>
                                    <input
                                      type="number"
                                      // readOnly
                                      placeholder={x.making_Percentage}
                                      value={x.making_Percentage}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          x.id,
                                          "making_Percentage"
                                        )
                                      }
                                    />
                                  </td>
                                ) : null}
                                {stockType === "Labelled" ? (
                                  <td>
                                    <input
                                      type="number"
                                      placeholder={x.making_Fixed_Amt}
                                      value={x.making_Fixed_Amt}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          x.id,
                                          "making_Fixed_Amt"
                                        )
                                      }
                                      // readOnly
                                    />
                                  </td>
                                ) : null}
                                {/* <td>
                                <input
                                  type="number"
                                  placeholder={x.making_Fixed_Wastage}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      x.id,
                                      "Making_Fixed_Wastage"
                                    )
                                  }
                                />
                              </td> */}
                                {/* <td>
                                <input
                                  type="number"
                                  placeholder={x.pieces}
                                  onChange={(e) =>
                                    handleInputChange(e, x.id, "pieces")
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  placeholder={x.size}
                                  onChange={(e) =>
                                    handleInputChange(e, x.id, "Size")
                                  }
                                />
                              </td> */}
                                {/* {stockType === "Labelled" ? (
                                  <td>
                                    <input
                                      type="number"
                                      placeholder={x.mrp}
                                      value={x.mrp}
                                      onChange={(e) =>
                                        handleInputChange(e, x.id, "mrp")
                                      }
                                    />
                                  </td>
                                ) : null} */}
                                {stockType !== "Labelled" ? (
                                  <td>
                                    <input
                                      type="number"
                                      value={x.quantity}
                                      onChange={(e) =>
                                        handleInputChange(e, x.id, "quantity")
                                      }
                                    />
                                  </td>
                                ) : null}
                                {stockType !== "Labelled" ? (
                                  <td>
                                    <input
                                      type="number"
                                      value={x.pieces}
                                      onChange={(e) =>
                                        handleInputChange(e, x.id, "pieces")
                                      }
                                    />
                                  </td>
                                ) : null}
                                {/* <td>
                                <input
                                  type="text"
                                  placeholder={x.description}
                                  onChange={(e) =>
                                    handleInputChange(e, x.id, "description")
                                  }
                                />
                              </td>

                              <td>
                                <input
                                  type="text"
                                  placeholder={x.occasion}
                                  onChange={(e) =>
                                    handleInputChange(e, x.id, "occasion")
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder={x.gender}
                                  onChange={(e) =>
                                    handleInputChange(e, x.id, "gender")
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder={x.featured}
                                  onChange={(e) =>
                                    handleInputChange(e, x.id, "Featured")
                                  }
                                />
                              </td> */}

                                {/* <td>
                    <button
                      type="submit"
                      onClick={() => {
                        setItemCode(x.itemCode);
                        setProductInEditImages(x.images);
                        setProductInEdit(x);
                        {
                          console.log(x.id);
                        }
                        updatedetailsBox(x.id);
                      }}
                    >
                      Update
                    </button>
                  </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  <div
                    // style={{ marginLeft: "10px" }}
                    style={{ width: "100%" }}
                    className="bulkProductAddingTableMain"
                  >
                    {deleteSelected ? (
                      <button
                        style={{
                          marginLeft: deleteSelected ? "1.5rem" : "0px",
                        }}
                        onClick={() => deleteAllProducts(selectedItems)}
                        className="bulkProductAddDeleteButton"
                      >
                        Delete Selected
                      </button>
                    ) : null}
                    {addedProducts.length > 0 ? (
                      <button
                        style={{
                          marginLeft: !deleteSelected ? "1.5rem" : "0px",
                          cursor: "pointer",
                        }}
                        // onClick={handleEditProducts}>
                        onClick={
                          stockType === "Labelled"
                            ? handleEditProducts
                            : handleEditProductsUnlabelled
                        }
                      >
                        <BiSave size={"12px"} style={{ marginRight: "5px" }} />
                        Save All
                      </button>
                    ) : null}

                    <Link to="/inventory">
                      <button
                        style={{
                          cursor: "pointer",
                          marginLeft:
                            addedProducts.length > 0 ? null : "1.5rem",
                        }}
                      >
                        <BiListUl
                          size={"12px"}
                          style={{
                            marginRight: "5px",
                          }}
                        />
                        Labelled List
                      </button>
                    </Link>
                    <Link to="/unlabelled_list">
                      <button style={{ cursor: "pointer" }}>
                        <BiListUl
                          size={"12px"}
                          style={{ marginRight: "5px" }}
                        />
                        Unlabelled List
                      </button>
                    </Link>
                    {/* <Link to="/admin_invoice">
                      <button style={{ cursor: "pointer" }}>
                        <FaFileInvoiceDollar
                          size={"12px"}
                          style={{ marginRight: "5px" }}
                        />
                        To Invoice
                      </button>
                    </Link> */}
                    {!hasUnsavedChanges && addedProducts.length > 0 ? (
                      <button
                        onClick={() => {
                          setAddedProducts([]);
                          setSelectedSku([]);
                          setSelectedSkuName("");
                          setSelectedFiles([]);
                          setStockType("Labelled");
                          setDeleteAll(false);
                          scrollToCenter("addBulkProductsBoxTop");
                        }}
                      >
                        <AiOutlineFileAdd
                          size={"12px"}
                          style={{ marginRight: "5px" }}
                        />
                        New Item
                      </button>
                    ) : null}
                    {deleteAll &&
                    stockType === "Labelled" &&
                    addedProducts.length > 0 ? (
                      <button
                        onClick={() => deleteAllProducts(allItemCodesArray)}
                        className="bulkProductAddDeleteButton"
                        style={{ backgroundColor: "#c14456" }}
                      >
                        Delete All
                      </button>
                    ) : null}
                  </div>
                  {/* </form> */}
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
