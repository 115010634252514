import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export default function PersonalDetails({
  email,
  setEmail,
  mobile,
  setMobile,
  personalDetails,
  setPersonalDetails,
}) {
  // const [email, setEmail] = React.useState("");
  // const [mobile, setMobile] = React.useState("");
  const [isEmailValid, setIsEmailValid] = React.useState(null); // Changed to null
  const [isMobileValid, setIsMobileValid] = React.useState(null); // Changed to null

  // Email validation function
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  // Mobile validation function (simple length check, adjust regex/validation logic as necessary for your requirements)
  const validateMobile = (mobile) => {
    return mobile.length === 10 && !isNaN(mobile);
  };

  // Handlers for input changes
  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    if (emailValue) {
      setIsEmailValid(validateEmail(emailValue));
    } else {
      setIsEmailValid(null); // Reset validation state when empty
    }
  };

  const handleMobileChange = (event) => {
    const mobileValue = event.target.value;
    setMobile(mobileValue);
    if (mobileValue) {
      setIsMobileValid(validateMobile(mobileValue));
    } else {
      setIsMobileValid(null); // Reset validation state when empty
    }
  };
  useEffect(() => {
    if (isMobileValid && isEmailValid) {
      console.log("Valid");
      setPersonalDetails("Okay");
    } else {
      console.log("No Valid");
      setPersonalDetails("Not Okay");
    }
  }, [mobile, email]);
  useEffect(() => {
    if (isMobileValid && isEmailValid) {
      console.log("Valid");
      setPersonalDetails("Okay");
    } else {
      console.log("No Valid");
      setPersonalDetails("Not Okay");
    }
  }, []);
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Personal details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="mobile"
            name="mobile"
            label="Mobile"
            fullWidth
            autoComplete="tel"
            variant="standard"
            value={mobile}
            onChange={handleMobileChange}
            InputProps={{
              endAdornment:
                isMobileValid === null ? null : isMobileValid ? (
                  <InputAdornment position="end">
                    <CheckCircleOutlineIcon style={{ color: "green" }} />
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end">
                    <ErrorOutlineIcon style={{ color: "red" }} />
                  </InputAdornment>
                ),
            }}
            error={isMobileValid === false}
          />
          {isMobileValid === false && (
            <FormHelperText error={true}>Invalid mobile number</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="email"
            variant="standard"
            value={email}
            onChange={handleEmailChange}
            InputProps={{
              endAdornment:
                isEmailValid === null ? null : isEmailValid ? (
                  <InputAdornment position="end">
                    <CheckCircleOutlineIcon style={{ color: "green" }} />
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end">
                    <ErrorOutlineIcon style={{ color: "red" }} />
                  </InputAdornment>
                ),
            }}
            error={isEmailValid === false}
          />
          {isEmailValid === false && (
            <FormHelperText error={true}>Invalid email address</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="Username"
            name="Username"
            label="Username"
            fullWidth
            autoComplete="Username"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="Password"
            name="Password"
            label="Password"
            fullWidth
            autoComplete="Password"
            variant="standard"
          />
        </Grid>

        {/* <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox color="secondary" name="saveAddress" value="yes" />
            }
            label="Use this address for payment details"
          />
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}
