import React, { useEffect, useState } from "react";
export default function GSAoutUsDesc() {
  return (
    <p>
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. In magnam aut
      omnis dicta nemo sapiente hic officiis beatae, facere numquam pariatur
      maxime molestiae non excepturi repudiandae mollitia consectetur tempore
      fuga?
    </p>
  );
}
