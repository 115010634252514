import React from "react";
import AdminHeading from "../Heading/AdminHeading";
import AdminSecondNavbar from "../Heading/AdminSecondNavbar";
import { AiFillGold, AiOutlineGold } from "react-icons/ai";
import { MdBorderColor, MdOutlinePendingActions } from "react-icons/md";
import AdminBreadCrump from "../Heading/AdminBreadCrump";
import AdminDashboardCategories from "./AdminDashboardCategories";
import "../../PagesStyles/AdminHome.css";
import AdminDashboardReports from "./AdminDashboardReports";
import AdminLocation from "./AdminLocation";
import { useSelector } from "react-redux";

export default function AdminHome() {
  const allStates = useSelector((state) => state);
  const adminLoggedIn = allStates.reducer1;
  console.log(adminLoggedIn.toString());
  return (
    <div>
      <AdminHeading />
      <div
        className="adminMainBodyBox"
        // style={{ paddingTop: "130px" }}
      >
        <AdminBreadCrump
          title={"Welcome !"}
          companyName={"Loyalstring"}
          module={"Dashboard"}
          page={"Home"}
        />
        <AdminDashboardCategories />
        <AdminDashboardReports />
        <AdminLocation />
      </div>
    </div>
  );
}
