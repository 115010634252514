import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { countries } from "../../../Api/CountriesList";

export default function AddressDetails() {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Address Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            id="WebsiteAddress"
            label="WebsiteAddress"
            fullWidth
            autoComplete="WebsiteAddress"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="StreetAddress"
            label="Street Address"
            fullWidth
            autoComplete="StreetAddress"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="Town/Village"
            label="Town / Village"
            fullWidth
            autoComplete="Town/Village"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="City"
            label="City"
            fullWidth
            autoComplete="City"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="State"
            label="State"
            fullWidth
            autoComplete="State"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {/* <TextField
            required
            id="Country"
            label="Country"
            fullWidth
            autoComplete="Country"
            variant="standard"
          /> */}
          <Autocomplete
            id="country-select-demo"
            sx={{ width: 250 }}
            options={countries}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  alt=""
                />
                {option.label} ({option.code}) +{option.phone}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a country"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="PostalCode"
            label="PostalCode"
            fullWidth
            autoComplete="PostalCode"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="PanNo"
            label="PanNo"
            fullWidth
            autoComplete="PanNo"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="GSTNo"
            label="GSTNo"
            fullWidth
            autoComplete="GSTNo"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="AadharNo"
            label="AadharNo"
            fullWidth
            autoComplete="AadharNo"
            variant="standard"
          />
        </Grid>
        {/* <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label="Remember credit card details for next time"
          />
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}
