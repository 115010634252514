import React, { useEffect, useState } from "react";
import AdminHeading from "../Heading/AdminHeading";
import AdminBreadCrump from "../Heading/AdminBreadCrump";
import "../../PagesStyles/AdminMasters.css";
import { TbCircleNumber1 } from "react-icons/tb";
import { a18, a35, a68, a69, a7, a70 } from "../../../Api/RootApiPath";
import { useSelector } from "react-redux";
import { RiListUnordered, RiPlayListAddLine } from "react-icons/ri";
import AlertMessage from "../../../Other Functions/AlertMessage";

export default function AdminAddStone() {
  const [active, setActive] = useState("List");
  const [showError, setShowError] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [messageToShow, setMessageToShow] = useState("");

  const [allStones, setAllStones] = useState([]);
  const [newStone, setNewStone] = useState({
    stoneName: "",
    slug: "",
    parent: "",
    rate: "",
    description: "",
  });
  const allStates = useSelector((state) => state);
  const adminLoggedIn = allStates.reducer1;
  let Entryby_Staff_id = parseInt(adminLoggedIn);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const fetchAllStones = async () => {
    try {
      const response = await fetch(a68);
      const data = await response.json();
      setAllStones(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllStones();
  }, []);
  console.log(allStones);
  const [editingId, setEditingId] = useState(null);
  const [editedData, setEditedData] = useState({});

  const handleEditClick = (id) => {
    setEditingId(id);
    // Find the item with the selected ID and set its data in the state
    const selectedItem = allStones.find((x) => x.id === id);
    setEditedData(selectedItem);
  };

  const handleSaveClick = () => {
    handleSubmit();
    // Save the edited data to your state or send it to an API
    console.log("Edited Data:", editedData);
    setEditingId(null); // Exit editing mode
  };
  const handleSubmit = async () => {
    if (
      editedData.stoneName !== "" &&
      editedData.parentName !== "" &&
      editedData.rate !== ""
    ) {
      try {
        const response = await fetch(a70, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editedData),
        });
        const data = await response.json();
        if (data.message) {
          // alert(data.message);
          setMessageType("error");
          setMessageToShow(data.message);
          setShowError(true);
        } else {
          setMessageType("success");
          setMessageToShow("Updated successfully");
          setShowError(true);
        }
        console.log(data, "updated");
        fetchAllStones();
      } catch (error) {
        console.error(error);
      }
    } else {
      alert("Please Correct errors");
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update the edited data in the state
    setEditedData({ ...editedData, [name]: value });
  };
  console.log(newStone, "newStone");
  const handleNewStoneChange = (e) => {
    const { name, value } = e.target;
    // Update the edited data in the state
    setNewStone({ ...newStone, [name]: value });
  };
  const addNewStone = async (e) => {
    e.preventDefault();
    const formData = {
      stoneName: newStone.stoneName,
      slug: newStone.slug,
      parent: newStone.parent,
      rate: newStone.rate,
      description: newStone.description,
    };
    try {
      const response = await fetch(a69, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      fetchAllStones();
      setActive("List");
      setNewStone({
        stoneName: "",
        slug: "",
        parent: "",
        rate: "",
        description: "",
      });
      if (data.message) {
        // alert(data.message);
        setMessageType("error");
        setMessageToShow(data.message);
        setShowError(true);
        setActive("AddNew");
      } else {
        setMessageType("success");
        setMessageToShow("Stone Added Successfully");
        setShowError(true);
      }
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowError(false);
    }, 2000);
  }, [showError]);
  return (
    <div>
      <AdminHeading />
      <div className="adminMainBodyBox">
        {showError ? (
          <AlertMessage message={messageToShow} type={messageType} />
        ) : null}
        <AdminBreadCrump
          title={"Add Stone"}
          companyName={"Loyalstring"}
          module={"Masters"}
          page={"Stones"}
        />
        <div className="adminAddCategoryMainBox">
          <div className="adminAddCategoryInnerBox">
            <div className="adminAddCategoryInnerBoxTitlesBox">
              <div
                onClick={() => {
                  setActive("List");
                }}
                className={
                  active === "List"
                    ? "adminAddCategoryInnerBoxTitle"
                    : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                }
              >
                <div
                  className={
                    active === "List"
                      ? "adminAddCategoryInnerBoxTitleLogo"
                      : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                  }
                >
                  {/* 01 */}
                  <RiListUnordered />
                </div>
                <p>All Stones</p>
              </div>

              <div
                onClick={() => setActive("AddNew")}
                className={
                  active === "AddNew"
                    ? "adminAddCategoryInnerBoxTitle"
                    : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                }
              >
                <div
                  className={
                    active === "AddNew"
                      ? "adminAddCategoryInnerBoxTitleLogo"
                      : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                  }
                >
                  {/* 02 */}
                  <RiPlayListAddLine />
                </div>
                <p>Add Stone</p>
              </div>
            </div>
            <div
              className={
                active === "List" ? "adminCategoryListMainBox" : "none"
              }
            >
              <table>
                <thead>
                  <tr>
                    <th>Edit</th>
                    <th>ID</th>
                    <th>Stone Name</th>
                    <th>Slug</th>
                    <th>Parent</th>
                    <th>Rate</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {allStones.map((x) => (
                    <tr key={x.id}>
                      <td>
                        {editingId === x.id ? (
                          <button
                            className="adminAddCategorySaveButton"
                            onClick={handleSaveClick}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="adminAddCategoryEditButton"
                            onClick={() => handleEditClick(x.id)}
                          >
                            Edit
                          </button>
                        )}
                      </td>
                      <td>{x.id}</td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="stoneName"
                            value={editedData.stoneName || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.stoneName
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="slug"
                            value={editedData.slug || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.slug
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="parent"
                            value={editedData.parent}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.parent
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="number"
                            name="rate"
                            value={editedData.rate}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.rate
                        )}
                      </td>

                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="description"
                            value={editedData.description || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.description
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              className={
                active !== "List" ? "adminCategoryAddCategoryMainBox" : "none"
              }
            >
              <p>Add New Stone</p>
              <form onSubmit={addNewStone}>
                <div className="adminCategoryAddCategoryInnerBox">
                  <label>
                    Stone Name <sup> *</sup>
                  </label>
                  <input
                    name="stoneName"
                    value={newStone.stoneName}
                    onChange={handleNewStoneChange}
                    type="text"
                    required="required"
                  />
                  <label>Slug</label>
                  <input
                    name="slug"
                    value={newStone.slug}
                    onChange={handleNewStoneChange}
                    type="text"
                  />
                  <label>
                    Parent <sup> *</sup>
                  </label>
                  <input
                    name="parent"
                    value={newStone.parent}
                    required="required"
                    onChange={handleNewStoneChange}
                    // onInput={handleStoneParentInput}
                    list="stoneParentNamesList"
                    type="text"
                  />
                  <datalist id="stoneParentNamesList">
                    {Array.from(
                      new Set(allStones.map((stone) => stone.parent))
                    ).map((uniqueParent, index) => (
                      <option key={index} value={uniqueParent} />
                    ))}
                  </datalist>
                  <label>Rate</label>
                  <input
                    required="required"
                    name="rate"
                    value={newStone.rate}
                    onChange={handleNewStoneChange}
                    type="number"
                  />

                  <label>Description</label>
                  <input
                    name="description"
                    value={newStone.description}
                    onChange={handleNewStoneChange}
                    type="text"
                  />
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
