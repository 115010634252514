import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import PersonalDetails from "./PersonalDetails";
import AddressDetails from "./AddressDetails";
import SelectPlan from "./SelectPlan";
import Review from "./Review";
import logo from "../../../Images/loyalStringLogoWide.png";
import Customize from "./Customize";
import { useNavigate } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://loyalstring.com/">
        LoyalString
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const steps = [
  "Personal Details",
  "Address details",
  "Select Plan",
  "Customize",
];

export default function GSClientOnboarding() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [email, setEmail] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [personalDetails, setPersonalDetails] = React.useState(false);

  // Handlers for setting email and mobile
  const handleSetEmail = (newEmail) => setEmail(newEmail);
  const handleSetMobile = (newMobile) => setMobile(newMobile);
  const handleSetPersonalDetails = (status) => setPersonalDetails(status);

  const navigate = useNavigate();
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <PersonalDetails
            email={email}
            setEmail={handleSetEmail}
            mobile={mobile}
            setMobile={handleSetMobile}
            personalDetails={personalDetails}
            setPersonalDetails={handleSetPersonalDetails}
          />
        );
      case 1:
        return <AddressDetails />;
      case 2:
        return <SelectPlan />;
      case 3:
        return <Customize />;
      case 4:
        return <Review />;
      default:
        throw new Error("Unknown step");
    }
  }
  console.log(activeStep, "active step");

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/gsHome")}
              src={logo}
              alt="Loyalsting"
            />
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component="h1" variant="h4" align="center">
            Register Now
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step style={{ whiteSpace: "noWrap" }} key={label}>
                <StepLabel>
                  {/* {label} */}
                  <p style={{ fontSize: "12px" }}>{label}</p>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Thank you for your order.
              </Typography>
              <Typography variant="subtitle1">
                Your order number is #2001539. We have emailed your order
                confirmation, and will send you an update when your order has
                shipped.
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}

                {activeStep == 0 && personalDetails == "Okay" ? (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {activeStep === steps.length - 1 ? "Place order" : "Next"}
                  </Button>
                ) : activeStep == 1 || activeStep == 2 || activeStep == 3 ? (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {activeStep === steps.length - 1 ? "Place order" : "Next"}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => alert("Please Enter Correct Details")}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {activeStep === steps.length - 1 ? "Place order" : "Next"}
                  </Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </Paper>
        <Copyright />
      </Container>
    </React.Fragment>
  );
}
