import React, { useEffect, useState } from "react";
import AdminHeading from "../Heading/AdminHeading";
import AdminBreadCrump from "../Heading/AdminBreadCrump";
import "../../PagesStyles/AdminMasters.css";
import { TbCircleNumber1 } from "react-icons/tb";
import { a18, a20, a21, a22, a23, a36, a37 } from "../../../Api/RootApiPath";
import { useSelector } from "react-redux";
import { RiListUnordered, RiPlayListAddLine } from "react-icons/ri";
import AlertMessage from "../../../Other Functions/AlertMessage";

export default function AdminAddPurity() {
  const [active, setActive] = useState("List");
  const [showError, setShowError] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [messageToShow, setMessageToShow] = useState("");
  const [loading, setLoading] = useState(false);

  const [allCategories, setAllCategories] = useState([]);
  const [allPurities, setAllPurities] = useState([]);
  const [newProduct, setNewProduct] = useState({
    category: "",
    purity: "",
    label: "",
    finePercentage: "0",
  });
  const allStates = useSelector((state) => state);
  const adminLoggedIn = allStates.reducer1;
  let Entryby_Staff_id = parseInt(adminLoggedIn);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const fetchallPurities = async () => {
    try {
      const response = await fetch(a22);
      const data = await response.json();
      setAllPurities(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAllCategories = async () => {
    try {
      const response = await fetch(a18);
      const data = await response.json();
      setAllCategories(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchallPurities();
  }, []);
  useEffect(() => {
    fetchAllCategories();
  }, []);
  console.log(allPurities);
  const [editingId, setEditingId] = useState(null);
  const [editedData, setEditedData] = useState({});

  const handleEditClick = (id) => {
    setEditingId(id);
    // Find the item with the selected ID and set its data in the state
    const selectedItem = allPurities.find((x) => x.id === id);
    setEditedData(selectedItem);
  };

  const handleSaveClick = () => {
    handleSubmit();
    // Save the edited data to your state or send it to an API
    console.log("Edited Data:", editedData);
    setEditingId(null); // Exit editing mode
  };
  const handleSubmit = async () => {
    const formData = {
      Id: editingId,
      Category: editedData.category,
      Purity: editedData.purity,
      Label: editedData.label,
      finePercentage: editedData.finePercentage,
      TodaysRate: editedData.todaysRate,
    };
    try {
      const response = await fetch(a37, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.message) {
        // alert(data.message);
        setMessageType("error");
        setMessageToShow(data.message);
        setShowError(true);
      } else {
        setMessageType("success");
        setMessageToShow("Updated successfully");
        setShowError(true);
      }
      console.log(data, "updated");
      fetchallPurities();
    } catch (error) {
      console.error(error);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update the edited data in the state
    setEditedData({ ...editedData, [name]: value });
  };

  const handleNewPurityChange = (e) => {
    const { name, value } = e.target;
    // Update the edited data in the state
    setNewProduct({ ...newProduct, [name]: value });
  };
  const addNewPurity = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = {
      Category: newProduct.category,
      Purity: newProduct.purity,
      Label: newProduct.label,
      FinePercentage: newProduct.finePercentage,
    };
    try {
      const response = await fetch(a23, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      fetchallPurities();
      setActive("List");
      setNewProduct({
        category: "",
        productTitle: "",
        slug: "",
        label: "",
        finePercentage: "0",
        hsnCode: "",
        description: "",
      });
      if (data.message) {
        // alert(data.message);
        setMessageType("error");
        setMessageToShow(data.message);
        setShowError(true);
        setActive("AddNew");
      } else {
        setMessageType("success");
        setMessageToShow("Category Added Successfully");
        setShowError(true);
      }
      setLoading(false);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setShowError(false);
    }, 2000);
  }, [showError]);
  return (
    <div>
      <AdminHeading />
      <div className="adminMainBodyBox">
        {showError ? (
          <AlertMessage message={messageToShow} type={messageType} />
        ) : null}
        <AdminBreadCrump
          title={"Add Purity"}
          companyName={"Loyalstring"}
          module={"Masters"}
          page={"Purities"}
        />
        <div className="adminAddCategoryMainBox">
          <div className="adminAddCategoryInnerBox">
            <div className="adminAddCategoryInnerBoxTitlesBox">
              <div
                onClick={() => {
                  setActive("List");
                }}
                className={
                  active === "List"
                    ? "adminAddCategoryInnerBoxTitle"
                    : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                }
              >
                <div
                  className={
                    active === "List"
                      ? "adminAddCategoryInnerBoxTitleLogo"
                      : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                  }
                >
                  <RiListUnordered />
                </div>
                <p>All Purities</p>
              </div>

              <div
                onClick={() => setActive("AddNew")}
                className={
                  active === "AddNew"
                    ? "adminAddCategoryInnerBoxTitle"
                    : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                }
              >
                <div
                  className={
                    active === "AddNew"
                      ? "adminAddCategoryInnerBoxTitleLogo"
                      : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                  }
                >
                  <RiPlayListAddLine />
                </div>
                <p>Add Purity</p>
              </div>
            </div>
            <div
              className={
                active === "List" ? "adminCategoryListMainBox" : "none"
              }
            >
              <table>
                <thead>
                  <tr>
                    <th>Edit</th>
                    <th>ID</th>
                    <th>Category</th>
                    <th>Purity</th>
                    <th>Fine Percentage</th>
                    <th>Label</th>
                  </tr>
                </thead>
                <tbody>
                  {allPurities.map((x) => (
                    <tr key={x.id}>
                      <td>
                        {editingId === x.id ? (
                          <button
                            className="adminAddCategorySaveButton"
                            onClick={handleSaveClick}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="adminAddCategoryEditButton"
                            onClick={() => handleEditClick(x.id)}
                          >
                            Edit
                          </button>
                        )}
                      </td>
                      <td>{x.id}</td>
                      <td>
                        {editingId === x.id ? (
                          <select
                            required="required"
                            name="category"
                            value={editedData.category || ""}
                            onChange={handleInputChange}
                          >
                            <option value="">Select an option</option>
                            {allCategories.map((x) => {
                              return (
                                <option value={x.category}>{x.name}</option>
                              );
                            })}
                          </select>
                        ) : (
                          x.category
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="purity"
                            value={editedData.purity || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.purity
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="number"
                            name="finePercentage"
                            value={editedData.finePercentage || "0"}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.finePercentage
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="label"
                            maxLength="4"
                            value={(editedData.label || "").toUpperCase()} // Convert to uppercase
                            onChange={handleInputChange}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^A-Z0-9 ]/g,
                                ""
                              ); // Allow only uppercase letters, numbers, and spaces
                              handleInputChange(e);
                            }}
                          />
                        ) : (
                          x.label
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              className={
                active !== "List" ? "adminCategoryAddCategoryMainBox" : "none"
              }
            >
              <p>Add New Purity</p>
              <form onSubmit={addNewPurity}>
                <div className="adminCategoryAddCategoryInnerBox">
                  <label>
                    Category <sup> *</sup>
                  </label>
                  <select
                    required="required"
                    name="category"
                    value={newProduct.category}
                    onChange={handleNewPurityChange}
                  >
                    <option value="">Select an option</option>
                    {allCategories.map((x) => {
                      return <option value={x.category}>{x.name}</option>;
                    })}
                  </select>
                  <label>
                    Purity <sup> *</sup>
                  </label>
                  <input
                    name="purity"
                    value={newProduct.purity}
                    onChange={handleNewPurityChange}
                    type="text"
                    required="required"
                  />
                  <label>
                    Fine Percent <sup> *</sup>
                  </label>
                  <input
                    name="finePercentage"
                    value={newProduct.finePercentage}
                    onChange={handleNewPurityChange}
                    type="number"
                    required="required"
                  />
                  <label>
                    Label <sup> *</sup>
                  </label>
                  <input
                    name="label"
                    value={newProduct.label}
                    onChange={handleNewPurityChange}
                    type="text"
                    required="required"
                    maxLength="4"
                    placeholder="Uppercase, Numbers, and Symbols"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(
                        /[^A-Z0-9!@#$%^&*()_+{}|:;<>,.?~[\]\-=\\'"]+/g,
                        ""
                      );
                    }}
                  />

                  {!loading ? <button type="submit">Submit</button> : null}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
